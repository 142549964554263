import React, { useState, useEffect } from "react";
import { Header, MainMenu } from "../../Common/Header";
import { Grid, Paper, Box, Typography, Divider, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Tick symbol
import ExternalCustomerForm from "./ExternalCustomerForm";
import ExternalCustomerTable from "./ExternalCustomerTable";
import AssignCustomer from "./AssignCustomer"; // Import the new component
import { fetchApolloUser } from "../../../services/apollo";
import { useLocation } from "react-router-dom";
import AddedCustomerList from "./AddedCustomerList";
import { IntegrationService } from "../../../services/IntegrationService";

const steps = [
  { step: 1, title: "Search customer from Apollo" },
  { step: 2, title: "Select customer from list" },
  { step: 3, title: "Added customer list" },
  { step: 4, title: "Assign the Account Owner" },
];

const StepOne = ({ input, onChange, onSubmit }) => (
  <ExternalCustomerForm onSubmit={onSubmit} />
);

const StepTwo = ({ dataSource2, currentFilter, handleAddSelected, allCustomers }) => (
  <ExternalCustomerTable
    dataSource2={dataSource2}
    currentFilter={currentFilter}
    handleAddSelected={handleAddSelected}
    allCustomers={allCustomers}
  />
);
const StepThree = ({ dataSource2, currentFilter, handleAddSelected2, dataSource, allCustomers }) => (
  <AddedCustomerList
    dataSource2={allCustomers}
    currentFilter={currentFilter}
    handleAddSelected2={handleAddSelected2}
  />
);

export default function StepForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [input, setInput] = useState("");
  const [extCustomerData, setExtCustomerData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const location = useLocation();
  const { allCustomers } = location.state || {};
  const [loading, setLoading] = useState(false);
  console.log("allCustomers",allCustomers);
  console.log("extCustomerData",extCustomerData);
  

  const handleModalSubmit = async (payload) => {
    try {
      //const response = await fetchApolloUser(payload, "mixed_people");
      const response2 = await IntegrationService.fetchEnrichApollo(payload);
      //console.log("response2",response2);
      //console.log("response",response);
      
      if (response2?.data?.people?.length) {
        setExtCustomerData(response2.data.people);
        setCurrentStep(2);
      }
    } catch (error) {
      console.error("API Call Failed:", error);
    }
  };

  const handleAddSelected = (customersToAdd) => {
    setSelectedRows(customersToAdd.map((customer) => customer.id));
    setCurrentStep(3);
  };
  const handleAddSelected2 = (customersToAdd) => {
    setSelectedRows(customersToAdd);
    setCurrentStep(4);
  };
  const handleAddSelected3 = (customersToAdd) => {
    setSelectedRows(customersToAdd);
    setCurrentStep(3);
  };

  const renderStepComponent = () => {    
    switch (currentStep) {
      case 1:
        return <StepOne input={input} onChange={setInput} onSubmit={handleModalSubmit} />;
      case 2:
        return <StepTwo dataSource2={extCustomerData} currentFilter={currentFilter} handleAddSelected={handleAddSelected} allCustomers={allCustomers}/>;
      case 3:
          return <StepThree dataSource={extCustomerData} allCustomers={allCustomers} currentFilter={currentFilter} handleAddSelected2={handleAddSelected2} />;
      case 4:
        return <AssignCustomer customersToAdd={selectedRows} handleAddSelected3={handleAddSelected3}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      {/* <Header /> */}
      {/* <MainMenu /> */}
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sx={{ p: 4 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              {steps.map((item, index) => (
                <Box
                  key={item.step}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setCurrentStep(index + 1)}
                >
                  <Box
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      backgroundColor: currentStep > index + 1 ? "#4caf50" : "#e0e0e0",
                      color: currentStep > index + 1 ? "white" : "black",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      mb: 1,
                    }}
                  >
                    {currentStep > index + 1 ? (
                      <CheckCircleIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      index + 1
                    )}
                  </Box>
                  <Typography variant="body2" textAlign="center">
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Divider sx={{ mb: 3 }} />
            {renderStepComponent()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
