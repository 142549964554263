import api from "./api";

async function addIntegration(integrationData, Id) {
  const result = await api.post(`/api_provider/create`, integrationData);
  return result;
}

async function addIntegrationById(integrationId, payload) {
  const result = await api.post(
    `/api_integration_by_team/create/by_api_provider_id/${integrationId}`,
    payload
  );
  return result;
}

async function updateIntegration(integrationData, integrationId) {
  const result = await api.put(`/api_provider/update/${integrationId}`, integrationData);
  return result;
}

async function updateIntegrationByTeamId(integrationData, teamId) {
  const result = await api.put(`/api_integration_by_team/update/by_id/${teamId}`, integrationData);
  return result;
}

async function deleteIntegration(integrationId) {
  const result = await api.delete(`/api_provider/delete/${integrationId}`);
  return result;
}

async function getIntegrations() {
  const result = await api.get(`/api_provider/get/all`);
  return result;
}

async function getIntegrationById(integrationId) {
  const result = await api.get(`/api_provider/get/${integrationId}`);
  return result;
}

async function getIntegrationByTeamId(teamId) {
  const result = await api.get(`/api_integration_by_team/get/by_id/${teamId}`);
  return result;
}

async function fetchEnrichApollo(payload) {
  const result = await api.post(`/fetchContacts`,payload);
  return result;
}

export const IntegrationService = {
  addIntegration,
  getIntegrations,
  getIntegrationById,
  updateIntegration,
  deleteIntegration,
  addIntegrationById,
  getIntegrationByTeamId,
  updateIntegrationByTeamId,
  fetchEnrichApollo
};
