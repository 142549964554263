import React, { useState, useEffect } from 'react';
import { getUser, checkPhone } from '../Common/Utils';
import Backdrop from '../Common/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from "../../services/api";
import './Settings.scss';

const UpdateUser = async (data) => {
    const result = await api.put(
        `/updateUser`,
        data
    );
    return result;
};

const CompanyProfile = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");
    const [booleanList, setBooleanList] = React.useState([]);

    useEffect(() => {
        const usr = getUser();
        if (usr) {
            setData({
                smtp_server: usr.smtp_server,
                smtp_username: usr.smtp_username,
                smtp_sender_email: usr.smtp_sender_email,
                smtp_port: usr.smtp_port,
                smtp_password: usr.smtp_password,
                company_name: usr.company_name || '',
                number_of_employees: usr.number_of_employees || '',
                company_website: usr.company_website || '',
                organization_type: usr.organization_type || 'LLC',
                company_address: usr.company_address || '',
            });
        }
        setBooleanList([
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" }
        ]);
    }, []);

    const onChange = (e) => {
        const { name, value } = e?.target || {};
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            update(data);
        }
    };

    const phoneValidation = (e) => {
        const { value } = e?.target || {};
        if (value && value.length) {
            const isValid = checkPhone(value);
            setErr(isValid ? "" : "Enter a valid telephone number");
        }
    };

    const update = () => {
        if (data.smtp_username.length && data.smtp_server.length) {
            updateBasicInfo();
        } else {
            setErr("Fill all required fields");
        }
    };

    const updateBasicInfo = async () => {
        if (err.length) return;

        setLoading(true);
        UpdateUser(data)
            .then((res) => {
                if (res?.status === 200 && res?.data?.data) {
                    let user = localStorage.getItem("user");
                    user = user && JSON.parse(user);
                    localStorage.setItem("user", JSON.stringify({ ...user, ...res.data.data }));
                }
                setLoading(false);
            })
            .catch((error) => {
                const e = error?.response?.data?.message?.toString() || "";
                setErr(e.length ? e : "Something went wrong");
                setLoading(false);
            });
    };

    return (
        <div className='profile__form'>
            {/* Company Information */}
            <div className="form-group">
                <label htmlFor="company_name" className="form-label">Company Name<p className="star">*</p></label>
                <input
                    type="text"
                    id="company_name"
                    className="form-input"
                    name="company_name"
                    value={data.company_name || ''}
                    placeholder="Enter Company Name"
                    onChange={onChange}
                    onKeyPressCapture={onKeyPress}
                />
            </div>
            <div className="form-group">
                <label htmlFor="number_of_employees" className="form-label">No. of Employees<p className="star">*</p></label>
                <input
                    type="number"
                    id="number_of_employees"
                    className="form-input"
                    name="number_of_employees"
                    value={data.number_of_employees || ''}
                    placeholder="Enter Number of Employees"
                    onChange={onChange}
                    onKeyPressCapture={onKeyPress}
                />
            </div>
            <div className="form-group">
                <label htmlFor="company_website" className="form-label">Company Website<p className="star">*</p></label>
                <input
                    type="url"
                    id="company_website"
                    className="form-input"
                    name="company_website"
                    value={data.company_website || ''}
                    placeholder="Enter Company Website URL"
                    onChange={onChange}
                    onKeyPressCapture={onKeyPress}
                />
            </div>
            <div className="form-group">
                <label htmlFor="organization_type" className="form-label">Organization Type<p className="star">*</p></label>
                <FormControl fullWidth>
                    <Select
                        value={data.organization_type || 'LLC'}
                        onChange={onChange}
                        name="organization_type"
                        id="organization_type"
                        className="form-input"
                    >
                        <MenuItem value="organized">Organized</MenuItem>
                        <MenuItem value="unorganized">Unorganized</MenuItem>

                    </Select>
                </FormControl>
            </div>
            <div className="form-group">
                <label htmlFor="company_address" className="form-label">Company Address<p className="star">*</p></label>
                <input
                    type="text"
                    id="company_address"
                    className="form-input"
                    name="company_address"
                    value={data.company_address || ''}
                    placeholder="Enter Company Address"
                    onChange={onChange}
                    onKeyPressCapture={onKeyPress}
                />
            </div>


            {/* Error Message */}
            {err.length ? <span style={{ color: 'red' }}>{err}</span> : null}

            {/* Submit Button */}
            <button className="form-submit" onClick={update}>Update</button>
            <Backdrop show={loading} />
        </div>
    );
};

export default CompanyProfile;
