import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PhoneInTalk,
  Cancel,
  CallEnd,
  Mic,
  MicOff,
} from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import { CrmService } from "../../services";
import { crmActions } from "../../actions";
import AddModal from "../../voc/Common/AddModal";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import PauseIcon from "@mui/icons-material/Pause";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import StopCircleIcon from "@mui/icons-material/StopCircle";
import ChatBot from "./ChatBot";
import CustomerCreationModal from "./CustomerCreationModal";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
}));

const ModalBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#d9bda5",
  borderRadius: "8px",
  padding: "20px",
  width: "300px",
  boxShadow:
    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
  position: "relative",
  animation: "slide-in 0.5s ease-out, bounce 0.5s ease-in-out forwards",
  "@keyframes slide-in": {
    "0%": {
      transform: "translate(100%, 100%)",
      opacity: 0,
    },
    "100%": {
      transform: "translate(0, 0)",
      opacity: 1,
    },
  },
  "@keyframes bounce": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.05)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}));

const ProfilePicture = styled("img")({
  width: "60px",
  height: "60px",
  borderRadius: "50%",
});

const PulseButton = styled(Button)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",

  "&:hover": {
    backgroundColor: "#4CAF50",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    transition: "background-color 0.3s ease",

    "&::after": {
      content: "''",
      position: "absolute",
      top: "-50%",
      left: "-50%",
      width: "200%",
      height: "200%",
      borderRadius: "50%",
      backgroundColor: "#4CAF50",
      animation: "$pulse-animation 1s infinite",
      opacity: 0.5,
    },
  },

  "@keyframes pulse-animation": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.05)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}));

const IncomingCallModal = () => {
  const [callerDetails, setCallerDetails] = useState(null);
  const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
  const [isAddCustomerOpen, setIsAddCustomerOpen] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [status, setStatus] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [callLogs, setCallLogs] = useState([]);
  const [SummaryResponse, setSummaryResponse] = useState("");
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);
  const [transferNo, setTransferNo] = useState("");

  const {
    isCalling,
    callerNumber,
    callStatus,
    audioRef,
    isAccepted,
    dialerNum,
    isHold,
    isMuted,
    isRecording,
    ai_response,
    callType,
    callLogId,
  } = useSelector((state) => ({
    isCalling: state.customCall.isCalling,
    callerNumber: state.customCall.callerNumber,
    callStatus: state.customCall.callStatus,
    audioRef: state.customCall.audioRef,
    isAccepted: state.customCall.isAccepted,
    dialerNum: state.customCall.dialerNum,
    isHold: state.customCall.isHold,
    isMuted: state.customCall.isMuted,
    isRecording: state.customCall.isRecording,
    ai_response: state.customCall.ai_response,
    callType: state.customCall.callType,
    callLogId: state.customCall.callLogId,
  }));

  const dispatch = useDispatch();

  

  useEffect(() => {
    (async () => {
      const callerData = await CrmService.getCustomerDataByNo(dialerNum);
     
      dispatch(
        customCallActions.StoreCallLogs(
          callerData.data.customers[0].id,
          callType,
          "Failed",
          callerData.data.customers[0]?.first_phone
        )
      );

      setCallerDetails(callerData.data.customers[0]);
      setDisplayName(callerData.data.customers[0].first_name);
    })();
  }, [isCalling, dialerNum, dispatch]);

  const handleTransferCall = (target) => {
    dispatch(customCallActions.transferCallRequest(target));
  };

  const handleHoldCall = () => {
    dispatch(customCallActions.holdCallRequest());
  };

  const handleAccept = () => {
    dispatch(customCallActions.acceptCallRequest());
  };

  const handleUnHoldCall = () => {
    dispatch(customCallActions.unHoldCallRequest());
  };

  const handleMuteCall = () => {
    dispatch(customCallActions.muteCallRequest());
  };

  const handleUnMuteCall = () => {
    dispatch(customCallActions.unMuteCallRequest());
  };

  useEffect(() => {
    if (isCalling === true) {
      (async () => {
        const response = await CrmService.getCallsLogsByCustomerId(callerDetails?.id);
        const callLogs = response?.data?.call_logs || [];

        setCallLogs(callLogs);
      })();
    }
  }, [isCalling]);

  useEffect(() => {
    if (isCalling === true && callerDetails) {
      (async () => {
        const response = await CrmService.getSummaryResponse(
          callerDetails?.id || "8"
        );
        const Summary = response?.data || [];
        setSummaryResponse(Summary);
      })();
    }
  }, [isCalling, callerDetails?.id]);

  const handleDropCall = async () => {
    dispatch(customCallActions.declineCallRequest());
    dispatch(customCallActions.endCallRequest());
  };

  useEffect(() => {
    // reset state
    if (isCalling === false) {
      setCallerDetails(null);
      setDisplayName("");
      setCallLogs([]);
      setSummaryResponse("");
    }
  }, [isCalling]);

  const latestCalls = callLogs.slice(0, 2);

  const handleAddNoteClick = () => {
    setIsAddNoteOpen(true);
  };

  const handleCloseNote = () => {
    setIsAddNoteOpen(false);
  };

  const handleSaveNote = () => {
    const addNotesData = {
      content: noteContent,
      status: status,
    };
    dispatch(crmActions.addNotes(addNotesData, callerDetails?.id));
    handleCloseNote();
  };

  const handleAddCustomerClick = () => {
    setIsAddCustomerOpen(true);
  };

  const handleCloseCustomerModal = () => {
    setIsAddCustomerOpen(false);
  };


  console.log("callerDetails",callerDetails);


  return (
    <>
      <audio ref={audioRef} autoPlay id="remoteAudio" />
      <StyledModal
        open={isCalling}
        sx={{ zIndex: 1000, justifyContent: "center", alignItems: "center" }}
      >
        <ModalBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={() => setIsAssistantOpen(true)}>
              <AutoAwesomeIcon />
            </IconButton>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            marginTop={2}
          >
            <ProfilePicture src="https://via.placeholder.com/60" alt="Caller" />
            <Typography variant="h6" fontWeight="bold">
              {displayName}
            </Typography>

            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              {callerDetails?.first_phone || callerNumber}
            </Typography>
            <div>
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontSize: "15px",
                }}
              >
                <BusinessIcon fontSize="small" />{" "}
                {(callerDetails && callerDetails?.company) || "Not available"}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontSize: "15px",
                }}
              >
                <EmailIcon fontSize="small" />{" "}
                {(callerDetails && callerDetails?.email) || "Not available"}
              </Typography>
            </div>

            {/* Call History List with reduced spacing */}
            <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {/* Smaller Heading for Call History */}
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Call History:
              </Typography>
              {latestCalls.map((call, index) => (
                <ListItem
                  key={index}
                  sx={{ paddingLeft: 0, paddingTop: 0.5, paddingBottom: 0.5 }}
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <CallIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Call ${index + 1} Answered:`}
                    secondary={new Date(call.call_start_time).toLocaleString()}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      fontSize: "0.875rem",
                    }}
                    secondaryTypographyProps={{ fontSize: "0.75rem" }}
                  />
                </ListItem>
              ))}
            </List>

            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "12px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "7px",
              }}
            >
              {SummaryResponse.action_item}
            </Typography>
          </Box>

          {/* Add Note Button */}
          <Box display="flex" justifyContent="center" marginTop={2}>
            {callerDetails === null ? (
              // If callerDetails is null, show the Add Customer button
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCustomerClick}
              >
                Add Customer
              </Button>
            ) : (
              // If callerDetails is not null, show the Add Note button
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNoteClick}
              >
                Add Note
              </Button>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography variant="p" fontWeight="bold" marginTop={2}>
              {callStatus}
            </Typography>
          </Box>

          {/* Call Actions */}
          {!isAccepted ? (
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <PulseButton
                variant="contained"
                color="success"
                startIcon={<PhoneInTalk />}
                onClick={handleAccept}
              >
                Accept
              </PulseButton>
              <Button
                variant="contained"
                color="error"
                startIcon={<Cancel />}
                onClick={handleDropCall}
              >
                Reject
              </Button>
            </Box>
          ) : (
            <>
              <Box marginTop={2}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={2}>
                    {!isMuted ? (
                      <IconButton onClick={handleMuteCall}>
                        <Mic />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handleUnMuteCall}>
                        <MicOff />
                      </IconButton>
                    )}
                  </Grid>
                  {/* <Grid item xs={2}>
                    <IconButton onClick={() => setSpeakerOn(!speakerOn)}>
                      {speakerOn ? <VolumeUp /> : <VolumeOff />}
                    </IconButton>
                  </Grid> */}
                  <Grid item xs={2}>
                    {!isHold ? (
                      <IconButton onClick={handleHoldCall}>
                        <PauseIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handleUnHoldCall}>
                        <StopCircleIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      sx={{
                        backgroundColor: isRecording && "#f44336",
                      }}
                      onClick={() => {
                        // isRecording
                        //   ? transcribeAudio()
                        //   : dispatch(customCallActions.startCaptureVoice());
                      }}
                    >
                      <RadioButtonCheckedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => setOpenTransferModal(true)}>
                      <MoveUpIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" marginTop={2}>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<CallEnd />}
                    onClick={handleDropCall}
                  >
                    Drop Call
                  </Button>
                </Box>
              </Box>

              <Dialog
                open={openTransferModal}
                onClose={() => setOpenTransferModal(false)}
                maxWidth="md"
              >
                <DialogTitle>Transfer Call</DialogTitle>
                <DialogContent>
                  {/* <TextField label="Transfer To" fullWidth margin="dense" /> */}
                  <TextField
                    placeholder="Enter Extension"
                    fullWidth
                    margin="dense"
                    onChange={(e) => setTransferNo(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenTransferModal(false)}
                    color="inherit"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenTransferModal(false);
                      handleTransferCall(`sip:${transferNo}@pbx.articence.com`);
                    }}
                    color="primary"
                  >
                    Transfer
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {/* Conditionally render AddModal */}
          {isAddNoteOpen && (
            <AddModal
              modalShow={isAddNoteOpen}
              handleClose={handleCloseNote}
              noteContent={noteContent}
              setNoteContent={setNoteContent}
              status={status}
              setStatus={setStatus}
              handleSaveNote={handleSaveNote}
            />
          )}
          {isAddCustomerOpen && (
            <CustomerCreationModal
              open={isAddCustomerOpen}
              onClose={handleCloseCustomerModal}
            />
          )}

          {/* Add Note Form in Dialog */}
          {/* <Dialog open={isAddNoteOpen} onClose={handleCloseNote}>
          <DialogTitle>Add Note</DialogTitle>
          <DialogContent>
            <TextField
              label="Note Content"
              fullWidth
              multiline
              rows={3}
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              margin="dense"
            />
            <Select
              fullWidth
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              displayEmpty
              margin="dense"
            >
              <MenuItem value="">
                <em>Select Status</em>
              </MenuItem>
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNote} color="inherit">
              Close
            </Button>
            <Button onClick={handleSaveNote} color="primary">
              Save Note
            </Button>
          </DialogActions>
        </Dialog> */}
          {/* ------------------  Call Features---------------------------- */}
          {isAssistantOpen && (
            <ChatBot
              handleClose={() => setIsAssistantOpen(false)}
              transcription={ai_response?.transcription}
              message={ai_response?.message}
            />
          )}
          {/* ------------------  Call Features---------------------------- */}
        </ModalBox>
      </StyledModal>
    </>
  );
};

export default IncomingCallModal;
