import React, { useState, useEffect, useRef } from "react";
import { getLocalAccessToken } from "../../../helpers";
import { Button, Modal } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { CardContent, Grid, InputLabel } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Typography,
} from "@mui/material";
import SimpleCall from "./SimpleCall";
import Card from "@mui/material/Card";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";

import CallIcon from "@mui/icons-material/Call";
import rington from "../../../sounds/dial-tone-joshua-chivers-low-1-00-16.mp3";
import RulesModal from "./RulesModal";
import TimezoneDropdown from "./TimezoneDropdown";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  // Registerer
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CallModal = ({ isVisible, customerId, phoneNumber, onClose }) => {
  // const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  // const callAi = useSelector((state) => state.callAi);
  const [recording, setRecording] = useState(false);
  const [aiMakeVisible, setAiMakeVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [transcriptionData, setTranscriptionData] = useState([]); // State to store transcription messages
  const [showTranscriptionPopup, setShowTranscriptionPopup] = useState(false);

  const [selectedTimezone, setSelectedTimezone] = useState("");

  // CALL DURATION
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const timerRef = useRef(null);
  const [voiceGender, setVoiceGender] = useState("female");
  const [voiceLanguage, setVoiceLanguage] = useState("en_US");
  const [schedule, setSchedule] = useState("");
  // const [timezone, setTimezone] = useState("-5.5");
  const [formdata, setFormData] = useState({
    purpose: "",
    prompt: "",
    rules: "",
  });

  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone);
    console.log("Selected Timezone:", timezone);
  };

  const formatTime = (time) => {
    const hours = String(Math.floor(time / 3600000)).padStart(2, "0");
    const minutes = String(Math.floor((time % 3600000) / 60000)).padStart(
      2,
      "0"
    );
    const seconds = String(Math.floor((time % 60000) / 1000)).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };
  const onSubmitCall = () => {
    const targetAudio = document.getElementsByClassName("audioBtn")[0];
    if (value == 0) {
      // dispatch(callAction.callAi(customerId, formdata));
      const fetchStreamUrl = async () => {
        try {
          formdata["recording"] = recording;
          formdata["audio"] = {
            service: "piper",
            language: voiceLanguage,
            voice: "kathleen",
            quality: "low",
            gender: voiceGender,
          };
          const response = await fetch(
            `https://vocbetaapp.articence.com/voc_crm/customers/${customerId}/call/initiate`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getLocalAccessToken(),
              },
              body: JSON.stringify(formdata),
            }
          );
          if (!response.body) {
            console.error("No response body");
            return;
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let buffer = "";

          const processStream = async () => {
            let result;
            while (!(result = await reader.read()).done) {
              buffer += decoder.decode(result.value, { stream: true });

              let boundary = buffer.indexOf("\n\n");
              while (boundary !== -1) {
                const chunk = buffer.slice(0, boundary).trim();
                buffer = buffer.slice(boundary + 2);

                if (chunk.startsWith("data: ")) {
                  const data = chunk.slice(6);
                  try {
                    console.log("data", data);

                    const jsonString = JSON.stringify(data);
                    const message = JSON.parse(jsonString);
                    const obj = eval("(" + message + ")");
                    if (obj.status == "calling") {
                      targetAudio.play();
                    } else {
                      targetAudio.pause();
                    }
                    if (obj.status == "disconnected") {
                      setIsRunning(false);
                    }
                    setMessages((prevMessages) => [...prevMessages, obj]);
                  } catch (e) {
                    console.error("Failed to parse message:", e);
                  }
                }

                boundary = buffer.indexOf("\n\n");
              }
            }
          };

          processStream();

          return () => reader.cancel();
        } catch (error) {
          console.error("Error fetching stream URL:", error);
        }
      };
      if (!isRunning) {
        fetchStreamUrl();
      }
    }
    if (value == 2) {
      // dispatch(callAction.callAi(customerId, formdata));
      const fetchStreamUrl = async () => {
        try {
          formdata["recording"] = recording;
          formdata["audio"] = {
            service: "piper",
            language: voiceLanguage,
            voice: "kathleen",
            quality: "low",
            gender: voiceGender,
          };
          formdata["schedule"] = schedule;
          formdata["timezone"] = selectedTimezone;
          const response = await fetch(
            `https://vocbetaapp.articence.com/voc_crm/customers/${customerId}/call/schedule`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getLocalAccessToken(),
              },
              body: JSON.stringify(formdata),
            }
          );
          if (!response.body) {
            console.error("No response body");
            return;
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let buffer = "";

          const processStream = async () => {
            let result;
            while (!(result = await reader.read()).done) {
              buffer += decoder.decode(result.value, { stream: true });

              let boundary = buffer.indexOf("\n\n");
              while (boundary !== -1) {
                const chunk = buffer.slice(0, boundary).trim();
                buffer = buffer.slice(boundary + 2);

                if (chunk.startsWith("data: ")) {
                  const data = chunk.slice(6);
                  try {
                    console.log("data", data);

                    const jsonString = JSON.stringify(data);
                    const message = JSON.parse(jsonString);
                    const obj = eval("(" + message + ")");
                    if (obj.status == "calling") {
                      targetAudio.play();
                    } else {
                      targetAudio.pause();
                    }
                    if (obj.status == "disconnected") {
                      setIsRunning(false);
                    }
                    setMessages((prevMessages) => [...prevMessages, obj]);
                  } catch (e) {
                    console.error("Failed to parse message:", e);
                  }
                }

                boundary = buffer.indexOf("\n\n");
              }
            }
          };

          processStream();

          return () => reader.cancel();
        } catch (error) {
          console.error("Error fetching stream URL:", error);
        }
      };
      if (!isRunning) {
        fetchStreamUrl();
      }
    }

    if (isRunning) {
      clearInterval(timerRef.current);
    } else {
      const startTime = Date.now() - time;
      timerRef.current = setInterval(() => {
        setTime(Date.now() - startTime);
      }, 1000);
    }
    setIsRunning(!isRunning);
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateTimeChange = (newValue) => {
    setSchedule(newValue);
    // Additional logic to handle the new date/time value can be added here
  };

  const handleAudio = (event) => {
    const isChecked = event.target.checked;
    setRecording(isChecked);

    const audio = new Audio("audio/call_record.wav");
    if (isChecked) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const handleChangeRecord = (event) => {
    setRecording(event.target.checked);
    handleAudio(event);
  };

  return (
    <>
      <Box centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Call</Modal.Title>
        </Modal.Header> */}
        <Box>
          <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Call with AI" />
                {/* <Tab label="Call" /> */}
                <Tab label="Schedule AI Call" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Typography>Initiate Call :</Typography>}
                      label=""
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Typography>
                          Call Status :{" "}
                          {messages.length
                            ? messages[messages.length - 1].status
                            : null}
                        </Typography>
                      }
                      label=""
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Typography>
                          Call Duration : {formatTime(time)}
                        </Typography>
                      }
                      label=""
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid item xs={12} pb={2}>
                <Divider>
                  <Chip label="Setup" />
                </Divider>
              </Grid>
              <Grid container md={12} spacing={0.5} pb={2}>
                <Grid item md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Purpose"
                    name="purpose"
                    onChange={handleEdit}
                    variant="outlined"
                    sx={{ margin: "3px 0px", width: "99%" }}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Prompt"
                    name="prompt"
                    onChange={handleEdit}
                    variant="outlined"
                    sx={{ margin: "3px 0px", width: "99%" }}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Rules"
                    name="rules"
                    onChange={handleEdit}
                    variant="outlined"
                    sx={{ margin: "3px 0px", width: "99%" }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} pb={2} spacing={0.5}>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Voice Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Filler Tone"
                      value={voiceGender}
                      onChange={(e) => setVoiceGender(e.target.value)}
                    >
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Voice Language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Filler Tone"
                      value={voiceLanguage}
                      onChange={(e) => setVoiceLanguage(e.target.value)}
                    >
                      <MenuItem value="en_US">English (US)</MenuItem>
                      <MenuItem value="hi">Hindi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={0.5} pb={1}>
                <Grid item md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={aiMakeVisible}
                          onChange={(e) => setAiMakeVisible(e.target.checked)}
                        />
                      }
                      label="Make Visible"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={recording}
                          //onChange={(e) => setRecording(e.target.checked)}
                          onChange={handleChangeRecord}
                        />
                      }
                      label="Record Call"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={0.5} pb={1}>
                <Grid item md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={recording}
                          onChange={(e) => setRecording(e.target.checked)}
                        />
                      }
                      label="Record Call Transcription"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              {aiMakeVisible ? (
                messages.length ? (
                  <>
                    <h4>AI Chat Box</h4>
                    <Card sx={{ border: "1px solid grey" }}>
                      <CardContent sx={{ padding: 0 }}>
                        <List style={{ maxHeight: "400px", overflow: "auto" }}>
                          {messages.map((message, index) => (
                            <ListItem
                              key={index}
                              style={{
                                textAlign:
                                  message.status === "ai_response"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              <ListItemText
                                primary={message.text}
                                secondary={message.status}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </>
                ) : null
              ) : null}
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>
              <SimpleCall phoneNumber={phoneNumber} customerId={customerId} />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={1}>
              <Grid container spacing={2} pb={2}>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Setup" />
                  </Divider>
                </Grid>
              </Grid>
              <Grid container xs={12} pb={2}>
                <Grid item xs={6}>
                  Call Status :{" "}
                </Grid>
                <Grid item xs={6}>
                  Record Consent :{formatTime(time)}
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={0.5} pb={2}>
                <Grid item md={4}>
                  <TextField
                    id="outlined-basic"
                    label="Purpose"
                    name="purpose"
                    onChange={handleEdit}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="outlined-basic"
                    label="Prompt"
                    name="prompt"
                    onChange={handleEdit}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="outlined-basic"
                    label="Rules"
                    name="rules"
                    onChange={handleEdit}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} pb={2} spacing={0.5}>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Voice Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Filler Tone"
                      value={voiceGender}
                      onChange={(e) => setVoiceGender(e.target.value)}
                    >
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Voice Language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Filler Tone"
                      value={voiceLanguage}
                      onChange={(e) => setVoiceLanguage(e.target.value)}
                    >
                      <MenuItem value="en_US">English (US)</MenuItem>
                      <MenuItem value="hi">Hindi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={0.5} pb={2}>
                <Grid item md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Make Visible"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={recording}
                          onChange={(e) => setRecording(e.target.checked)}
                        />
                      }
                      label="Record Call"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={0.5} pb={2}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      disablePast
                      label="Schedule at"
                      value={schedule}
                      onChange={handleDateTimeChange}
                      renderInput={(props) => (
                        <TextField {...props} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={0.5} pb={2}>
                <Grid item xs={12}>
                  <TimezoneDropdown
                    selectedTimezone={selectedTimezone}
                    handleTimezoneChange={handleTimezoneChange}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
          </div>
        </Box>
        {value == 2 ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => onSubmitCall()}>
              <CallIcon /> Schedule Call
            </Button>
          </Modal.Footer>
        ) : value !== 1 ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onSubmitCall()}>
              <CallIcon /> {isRunning ? "Call End" : "Call"}
            </Button>
          </Modal.Footer>
        ) : null}
        <audio className="audioBtn" id="remoteAudio">
          <source src={rington}></source>
        </audio>
      </Box>
    </>
  );
};

export default CallModal;
