import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, IconButton, TextField, Button, MenuItem, Typography, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { AgentsService } from '../../../services/AgentsService';
import './AgentModal.scss';
import CloseIcon from '@mui/icons-material/Close';

const AIAgentModal = ({ open, onClose, handleSubmit, handleAIAgentSubmit, editAIAgent, selectedAgentId, setEditAIAgent }) => {
  const [editData, setEditData] = useState(null);
  const [agentData, setAgentData] = useState({
    agent_name: "",
    agent_type: "",
    preferred_lang: '',
    call_script: '',
    extension: '',
    sp_instructions: '',
    status: editAIAgent && editData ? editData.status : 'in-active',
  });
  const [agentDatas, setAgentDatas] = useState(null);

  const [isFormValid, setIsFormValid] = useState(false);
  const [Extension, setExtension] = useState(null);
  const [nameError, setNameError] = useState("");
  const [initialData, setInitialData] = useState(editData || {});

  //const agentTypeOptions = ['Voice Agent', 'AI Agent', 'Text Agent', 'Video Agent', 'Email Agent'];
  
  const agentTypeOptions = [
    { label: 'Voice Agent', description: 'Handles voice calls with customers' },
    { label: 'AI Agent', description: 'Uses artificial intelligence for automated responses' },
    { label: 'Text Agent', description: 'Manages text-based communication' },
    { label: 'Video Agent', description: 'Facilitates video calls' },
    { label: 'Email Agent', description: 'Handles email communications' },
  ];

  const languageOptions = ['English', 'French'];
  const ExtensionOptions = ['1001', '1002', '1003'];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (editAIAgent) {
      setEditData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setAgentData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const Editrows = 
    {
      agent_id: 1,
      agent_name: "Agent 1",
      agent_type: "Inbound",
      preferred_lang: "English",
      call_script: "Welcome Script",
      extension: "101",
      sp_instructions: "Handle with care",
      status: "active"
    }

  const handleCheckboxChange = (event) => {
    setAgentData((prevData) => ({
      ...prevData,
      status: event.target.checked ? 'active' : 'in-active',
    }));
  };

  useEffect(() => {
    if (editAIAgent && editData) {
      setAgentData((prevData) => ({
        ...prevData,
        status: editData.status || 'in-active', // Use 'in-active' as default if null
      }));
    }
  }, [editAIAgent, editData]);


  

  const handleSubmitData = () => {
    const isNameValid = validateAgentName(agentData.agent_name);
    if (!editAIAgent && !isNameValid) return;

    if (editAIAgent) {
      const updatedEditData = { ...editData, status: agentData.status };

      handleSubmit(updatedEditData);
      setEditData(null);
      setAgentData("")

    } else {
      handleSubmit(agentData);
    }
    setEditData(false)

    onClose();
  };

  // Check if all required fields are filled, excluding "Special Instructions" (sp_instructions)
  useEffect(() => {
    const isValid = Object.entries(agentData)
      .filter(([key]) => key !== "sp_instructions") // Exclude sp_instructions from validation
      .every(([, value]) => value.trim() !== ""); // Check that each remaining field is filled

    setIsFormValid(isValid);
  }, [agentData]);

  useEffect(() => {
    if (Extension?.length > 0) {
      setAgentData((prevData) => ({
        ...prevData,
        extension: prevData.extension || Extension[0].Extensions,
      }));
    }
  }, [Extension]);
  
  useEffect(() => {
    if (open && editAIAgent) {
      const fetchAgentData = async () => {
        try {
          const data = await AgentsService.getAgentById(selectedAgentId)
          setEditData(data?.data);
        } catch (error) {
          console.error("Error fetching agent data:", error);
        }
      };

      fetchAgentData();
    }
  }, [open, setAgentData]);
 
  

  useEffect(() => {
    if (open) { 
      (async () => {
        const data = await AgentsService.getExtension();
        setExtension(data?.data);        
      })();
    }
  }, [open]);


  React.useEffect(() => {
    if (open) {
      setAgentData({
        agent_name: "",
        agent_type: "",
        preferred_lang: '',
        call_script: '',
        extension: '',
        sp_instructions: '',
        status: 'in-active'
      });
    }
  }, [open]);

  const handleClose = () => {
    setEditAIAgent(false);
    onClose();
    setNameError('');
  };

  useEffect(() => {
    const fetchAgents = async () => {
      const data = await AgentsService.getAgents();
      setAgentDatas(data?.data?.ai_agents || []);
    };
    fetchAgents();
  }, []);

  const validateAgentName = (name) => {
    // Check if the name already exists
    console.log("agentData", agentData);
    
    const isNameExists = agentDatas?.some((agent) => agent.agent_name.toLowerCase() === name.toLowerCase());
    if (isNameExists) {
      setNameError('Agent name already exists');
      return false;
    }

    setNameError(''); // Clear error if name is valid
    return true;
  };

  
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        {editAIAgent ? <h2>Edit AI Agent</h2> : <h2>Create AI Agent</h2>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={
                <>
                  {"Agent Name"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              name="agent_name"
              value={editAIAgent == false ? agentData.agent_name : editData?.agent_name}
              onChange={handleChange}
              placeholder="Enter agent name"
              variant="outlined"
              InputLabelProps={editAIAgent ? { shrink: true } : ""}
              onBlur={() => validateAgentName(editAIAgent == false ? agentData.agent_name : "")}
              error={editAIAgent == false ? nameError : ""}
              helperText={editAIAgent == false ? nameError : ''}             
              />
          </Grid>

          <Grid item xs={12}>
          <TextField
      fullWidth
      select
      label={
        <>
          {"Agent Type"}
          <Typography component="span" color="error" style={{ marginLeft: 4 }}>
            *
          </Typography>
        </>
      }
      name="agent_type"
      value={editAIAgent === false ? agentData.agent_type : editData?.agent_type || agentData.agent_type}
      onChange={handleChange}
      variant="outlined"
    >
      {agentTypeOptions.map((option) => (
        <MenuItem key={option.label} value={option.label}>
          <Tooltip title={option.description} arrow>
            <span>{option.label}</span>
          </Tooltip>
        </MenuItem>
      ))}
    </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label={
                <>
                  {"Preferred Language"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              name="preferred_lang"
              value={editAIAgent == false ? agentData.preferred_lang : editData?.preferred_lang ?? agentData.preferred_lang}
              onChange={handleChange}
              variant="outlined"
              //InputLabelProps={{ shrink: true }}
            >
              {languageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={
                <>
                  {"Call Script" }
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              name="call_script"
              value={editAIAgent == false ? agentData.call_script : editData?.call_script}
              onChange={handleChange}
              placeholder="Enter call script"
              variant="outlined"
              InputLabelProps={editAIAgent ? { shrink: true } : ""}             
            />
          </Grid>

          <Grid item xs={12}>
  <TextField
    fullWidth
    select
    label={
      <>
        {"Extension"}
        <Typography component="span" color="error" style={{ marginLeft: 4 }}>
          *
        </Typography>
      </>
    }
    name="extension"
    value={Extension?.[0]?.Extensions ?? ""}
    placeholder="Enter link or number"
    variant="outlined"
    disabled
  >
    {Extension?.map((option) => (
      <MenuItem key={option.Extensions} value={option.Extensions}>
        {option.Extensions}
      </MenuItem>
    ))}
  </TextField>
</Grid>


          <Grid item xs={12}>
            <TextField
              fullWidth
              label={
                <>
                  {"Special Instructions"}
                  {/* <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography> */}
                </>
              }
              name="sp_instructions"
              value={editAIAgent == false ? agentData.sp_instructions : editData?.sp_instructions}
              onChange={handleChange}
              placeholder="Enter any additional instructions"
              variant="outlined"
              InputLabelProps={editAIAgent ? { shrink: true } : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agentData.status === 'active'}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>

          <Grid item xs={12}>
            <Tooltip title={editAIAgent == false ? (!isFormValid ? "Please fill the required fields" : ""): ""} placement="top" arrow>
              <span>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={() => {
                  //   handleSubmit(agentData);
                  //   handleAIAgentSubmit(agentData)
                  //   onClose();
                  // }}
                  onClick={handleSubmitData}
                  disabled={editAIAgent == false ? !isFormValid : ""}
                >
                  {editAIAgent ? "Update Agent" : "Create Agent"}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AIAgentModal;
