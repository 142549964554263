import { appointmentConstants } from "../constants/appointmentConstants";
import { appointmentService } from "../services/AppointmentService";
import { swalAlert } from "../voc/Common/SwalAlert";

// const addAppointments = (addAppointementsData, Id) => {
//     const request = () => ({
//       type: appointmentConstants.ADD_APPOINTMENT_REQUEST, 
//     });
  
//     const success = (appointments) => ({
//       type: appointmentConstants.ADD_APPOINTMENT_SUCCESS,
//       appointments,
//     });
  
//     const failure = (error) => ({ 
//       type: appointmentConstants.ADD_APPOINTMENT_FAILURE,
//       error,
//     }); 
  
//     return (dispatch) => {
//       dispatch(request());
//       appointmentService.addAppointments(addAppointementsData, Id).then(
//         (alerts) => {
//           dispatch(success(alerts));
//           swalAlert({
//             icon: "success",
//             title: "Appointment added successfully!",
//             showConfirmButton: true,
//           });
//         },
//         (error) => {
//           dispatch(failure(error?.response?.data?.message?.toString()));
//           swalAlert({
//             icon: "error",
//             title: error?.response?.data?.message?.toString(),
//             showConfirmButton: true,
//           });
//         }
//       );
//     };
//   };

const addAppointments = (addAppointementsData, Id) => {
  const request = () => ({ type: appointmentConstants.ADD_APPOINTMENT_REQUEST });
  const success = (appointments) => ({
    type: appointmentConstants.ADD_APPOINTMENT_SUCCESS,
    appointments,
  });
  const failure = (error) => ({
    type: appointmentConstants.ADD_APPOINTMENT_FAILURE,
    error,
  });

  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      appointmentService.addAppointments(addAppointementsData, Id).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Appointment added successfully!",
            showConfirmButton: true,
          });
          resolve();
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
          reject();
        }
      );
    });
};


  const deleteAppointments = (appointmentId) => {  
    const request = () => ({
      type: appointmentConstants.DELETE_APPOINTMENT_REQUEST,
    });
    
    const success = (Id) => ({
      type: appointmentConstants.DELETE_APPOINTMENT_SUCCESS,
      Id,
    });
    
    const failure = (error) => ({
      type: appointmentConstants.DELETE_APPOINTMENT_FAILURE,
      error,
    });
    
    return (dispatch) => {
      dispatch(request());
      appointmentService.deleteAppointments(appointmentId).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Appointment deleted successfully!",
            showConfirmButton: true,
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
    };
  };

  const editAppointments = (appointmentsData, appointmentId) => {
  
    const request = () => ({
      type: appointmentConstants.EDIT_APPOINTMENT_REQUEST,
    });
  
    const success = (alerts) => ({
      type: appointmentConstants.EDIT_APPOINTMENT_REQUEST,
      alerts,
    });
  
    const failure = (error) => ({
      type: appointmentConstants.EDIT_APPOINTMENT_REQUEST,
      error,
    });
    return (dispatch) => {
      dispatch(request());
      appointmentService.editAppointments(appointmentsData, appointmentId).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Appointment updated successfully!",
            showConfirmButton: true,
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
    };
  };

  export const appointmentActions = {
    addAppointments,
    deleteAppointments,
    editAppointments
  };