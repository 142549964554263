import React, { useState, useEffect } from "react";
import { Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";  // Assuming you're using Redux
import { crmActions } from "../../../actions";// Update the import based on your file structure
import { appointmentService } from "../../../services/AppointmentService";

const AssignCustomer = ({ customersToAdd, handleAddSelected3 }) => {
  const [selectedAccountOwner, setSelectedAccountOwner] = useState("");
  const [accountOwner, setAccountOwner] = useState([]);
  const dispatch = useDispatch();

  // Hardcoded values for the dropdown
  const accountOwners = ["Microsoft", "Google", "Apple"];

  useEffect(() => {
      (async () => {
        const data = await appointmentService.getUserExtension();
        setAccountOwner(data?.data)        
      })();

  }, []);

  const handleAccountOwnerChange = (event) => {
    setSelectedAccountOwner(event.target.value);
  };
  

  const assignHandleSubmit = () => {
    
    if (selectedAccountOwner) {
      
      // Prepare the payload from customersToAdd
      const payload = customersToAdd.map((customer) => ({
        ...customer,
        account_owner: selectedAccountOwner,  // Set the selected Account Owner here
        //Remove the unnecessary enriched fields
        company_name_for_emails: "",
        contact_owner: "",
        country: customer.country,
        departments: customer.departments,
        email: customer.email,
        email_confidence: customer.email_confidence ? customer.email_confidence : "" ,
        first_name: customer.first_name,
        first_phone: customer.first_phone,
        seniority: customer.seniority ? customer.seniority : "",
        state: customer.state,
        timezone: customer.timezone,
        title: customer.title,
        work_direct_phone: customer.work_direct_phone,
      }));

      // Dispatch the API call with the updated payload
      payload.forEach((customer) => {
        dispatch(crmActions.updateCustomerDataFormAPI(customer.id, customer));
      });
    }
  };

  return (
    <div>
      <h5>Assign Account Owner</h5>
      <FormControl fullWidth>
        <InputLabel>Account Owner</InputLabel>
        <Select
          value={selectedAccountOwner}
          onChange={handleAccountOwnerChange}
          label="Account Owner"
        >
          {accountOwner.map((owner) => (
            <MenuItem key={owner.id} value={owner.Name}>
              {owner.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={assignHandleSubmit} variant="contained" color="primary" className="mt-2">
        Assign
      </Button>
    </div>
  );
};

export default AssignCustomer;
