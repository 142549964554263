// TeamCreationForm.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";

const TeamCreationForm = ({ onCancel }) => {
  const [formData, setFormData] = useState({
    name: "",
    delegate: "",
    territories: "",
    description: "",
    members: [],
  });

  const membersList = [
    "John Doe",
    "Jane Smith",
    "Alice Brown",
    "Bob White",
    "Charlie Green",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMemberToggle = (member) => {
    setFormData((prev) => ({
      ...prev,
      members: prev.members.includes(member)
        ? prev.members.filter((m) => m !== member)
        : [...prev.members, member],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission
    console.log("Form Submitted:", formData);
    setFormData({ name: "", delegate: "", territories: "", description: "", members: [] });
    onCancel(); // Hide form after submission if desired
  };

  return (
    <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
      <Typography variant="h6" gutterBottom>
        Create New Team
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Team Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  label="Delegate"
                  name="delegate"
                  value={formData.delegate}
                  onChange={handleChange}
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Supervisor">Supervisor</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </TextField>
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  label="Territories"
                  name="territories"
                  value={formData.territories}
                  onChange={handleChange}
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="North">North</MenuItem>
                  <MenuItem value="South">South</MenuItem>
                  <MenuItem value="East">East</MenuItem>
                  <MenuItem value="West">West</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                padding: "10px",
                maxHeight: "340px",
                overflowY: "auto",
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Members
              </Typography>
              <FormGroup>
                {membersList.map((member, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={formData.members.includes(member)}
                        onChange={() => handleMemberToggle(member)}
                      />
                    }
                    label={member}
                  />
                ))}
              </FormGroup>
            </Box>
          </Grid>
        </Grid>

        {/* Submit and Cancel Buttons */}
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

TeamCreationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default TeamCreationForm;
