import { customCallConstant } from "../constants/customCallConstant";

export function customCall(state = { isCalling: false }, action) {
  console.log("action.type", action.type);
  switch (action.type) {
    case customCallConstant.REGISTER_SIP:
      return {
        ...state,
        isCalling: false,
        isAccepted: false,
        isHold: false,
        isRecording: false,
        ua: action.payload.ua,
        sipMsg: action.payload.sipMsg,
        sipStatus: action.payload.sipStatus,
        callStatus: "Registered",
        ai_response: "",
        callType: null,
        firstPhone: null,
      };

    case customCallConstant.GET_SESSION:
      return {
        ...state,
        session: action.payload,
      };

    case customCallConstant.GET_AUDIO_REF:
      return {
        ...state,
        isCalling: true,
        audioRef: action.payload,
      };

    case customCallConstant.MAKE_CALL:
      return {
        ...state,
        isAccepted: true,
        isCalling: true,
        dialerNum: action.payload,
        callStatus: "Calling",
      };

    case customCallConstant.SET_INCOMING_CALL:
      return {
        ...state,
        isCalling: true,
        isAccepted: false,
        incomingCall: action.payload.call,
        callerNumber: action.payload.callerNumber,
        callStatus: "Incoming Call",
        callType: action.payload.callType,
      };

    case customCallConstant.ACCEPT_CALL:
      if (state.incomingCall) {
        state.incomingCall.answer({
          mediaConstraints: { audio: true, video: false },
        });
      }
      return {
        ...state,
        isCalling: true,
        isAccepted: true,
        callStatus: "Call Connected",
      };

    case customCallConstant.MUTE_CALL:
      return {
        ...state,
        isCalling: true,
        isAccepted: true,
        isMuted: true,
        callStatus: "Call Muted",
      };

    case customCallConstant.UNMUTE_CALL:
      return {
        ...state,
        isCalling: true,
        isAccepted: true,
        isMuted: false,
        callStatus: "Call Connected",
      };

    case customCallConstant.HOLD_CALL:
      return {
        ...state,
        isHold: true,
        callStatus: "Call on Hold",
      };

    case customCallConstant.UNHOLD_CALL:
      return {
        ...state,
        isHold: false,
        callStatus: "Call Connected",
      };

    case customCallConstant.TRANSFER_CALL:
      return {
        ...state,
        isCalling: true,
        incomingCall: null,
        callerNumber: null,
        callStatus: "Call Transferred",
      };

    case customCallConstant.END_CALL:
      return {
        ...state,
        isCalling: false,
        incomingCall: null,
        callerNumber: null,
        dialerNum: null,
        callStatus: "Call Ended",
      };

    case customCallConstant.DECLINE_CALL:
      return {
        ...state,
        isCalling: false,
        incomingCall: null,
        callerNumber: null,
        dialerNum: null,
        callStatus: "Call Declined",
      };

    case customCallConstant.PROGRESS_CALL:
      return {
        ...state,
        isCalling: true,
        callStatus: "Call in Progress",
      };

    case customCallConstant.START_CAPTURE_VOICE:
      return {
        ...state,
        isRecording: true,
        callStatus: "Capturing Voice",
        customerVoiceCaptureStartTime: action.payload,
      };

    case customCallConstant.STOP_CAPTURE_VOICE:
      return {
        ...state,
        isRecording: false,
        callStatus: "Call Connected",
      };

    case customCallConstant.TRANSCRIPTION:
      return {
        ...state,
        ai_response: action.payload,
      };

    case customCallConstant.STORE_CALL_LOGS:
      return {
        ...state,
        callLogId: action.payload.callLogId,
        firstPhone: action.payload.firstPhone,
      };

    case customCallConstant.GET_CALL_LOGS:
      return {
        ...state,
        callLogs: action.payload,
      };

    case customCallConstant.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case customCallConstant.GET_CUSTOMER_STATUS:
      return {
        ...state,
        customerStatus: action.payload,
      };

    case customCallConstant.GET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.payload,
      };

    case customCallConstant.GET_ALL_EMAILS:
      return {
        ...state,
        allEmails: action.payload,
      };

    // case customCallConstant.GET_CALL_SUMMARY:
    //   return {
    //     ...state,
    //     callSummary: action.payload,
    //   };

    // call summary logic
    case customCallConstant.GET_CALL_SUMMARY:
      return {
        ...state,
        loading: true,
        callSummary: null,
      };

    case customCallConstant.GET_CALL_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        callSummary: action.callSummary,
        type: "GET_CALL_SUMMARY_SUCCESS",
      };

    case customCallConstant.GET_CALL_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        callSummary: null,
        type: "GET_CALL_SUMMARY_FAILURE",
      };
    case customCallConstant.GET_CALL_START_TIME:
      return {
        ...state,
        callStartTime: action.payload,
      };

    case customCallConstant.CAPTURE_AGENT_VOICE:
      return {
        ...state,
        isAgentVoiceRecording: true,
        callStatus: "Capturing Agent Voice",
        agentVoiceCaptureStartTime: action.payload,
      };

    case customCallConstant.STOP_CAPTURE_AGENT_VOICE:
      return {
        ...state,
        isAgentVoiceRecording: false,
        callStatus: "Call Connected",
      };

    default:
      return state;
  }
}
