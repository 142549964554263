import { campaignConstants } from "../constants";

export function campaign(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_CAMPAIGN_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_CAMPAIGN_BY_ID_SUCCESS:
      return {
        item: action.campaign.data.campaign,
      };
    case campaignConstants.GET_CAMPAIGN_BY_ID_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function campaigns(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_CAMPAIGNS_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_CAMPAIGNS_SUCCESS:
      return {
        items: action.campaigns.data.campaigns,
      };
    case campaignConstants.GET_CAMPAIGNS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function detail(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_DETAIL_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_DETAIL_BY_ID_SUCCESS:
      return {
        item: action.detail.data.detail,
      };
    case campaignConstants.GET_DETAIL_BY_ID_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function download(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_DOWNLOAD_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_DOWNLOAD_SUCCESS:
      // console.log("GETDOWNLOADSUCCESS", action);
      return {
        item: action.detail_id.data,
      };
    case campaignConstants.GET_DOWNLOAD_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function experience(state = {}, action) {
  // console.log(action);
  switch (action.type) {
    case campaignConstants.GET_EXPERIENCE_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_EXPERIENCE_SUCCESS:
      return {
        items: action.experience_type.data.experience,
      };
    case campaignConstants.GET_EXPERIENCE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function details(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_DETAILS_REQUEST:
      // console.log("GET DETAILS REQUEST");
      return {
        loading: true,
      };
    case campaignConstants.GET_DETAILS_SUCCESS:
      // console.log("GET DETAILS SUCCESS", action);
      /*var details = [];
            if(state.items) {
                details = state.items;
            }

            details.push(action.details.data.campaignDetails); */

      return {
        items: action.details.data.campaignDetails,
      };
    case campaignConstants.GET_DETAILS_FAILURE:
      return {
        error: action.error,
      };
    case campaignConstants.CREATE_DETAILS_SUCCESS:
      // console.log("CREATE DETAILS SUCCESS", action.detail.data.detail);
      return {
        ...state,
        items: [action.detail.data.detail],
      };
    default:
      return state;
  }
}

export function questDesc(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_QUEST_DESC_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_QUEST_DESC_SUCCESS:
      return {
        items: action.quest_desc,
        image: action.image_url,
        info: action.user_info,
        cond_flow: action.cond_flow,
        styleData: action.styleData,
      };
    case campaignConstants.GET_QUEST_DESC_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function answers(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_DATA_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_DATA_SUCCESS:
      return {
        items: action.answer_data.data.answers,
        answerLength: action.answer_data.data.answerLength,
      };
    case campaignConstants.GET_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function summary(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_SUMMARY_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_SUMMARY_SUCCESS:
      // console.log(action);
      return {
        items: action.summary.data.summary,
      };
    case campaignConstants.GET_SUMMARY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function recommended(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_RECOMMENDED_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_RECOMMENDED_SUCCESS:
      return {
        items: action.recommended.data.recommended.message,
        api_id: action.recommended.data.api_id,
        outlier: action.recommended.data.recommended.outlier,
        tldr: action.recommended.data.recommended.tldr,
      };
    case campaignConstants.GET_RECOMMENDED_FALIURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function api_recommended(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_API_RECOMMENDED_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_API_RECOMMENDED_SUCCESS:
      return {
        item: action.api_recommended.data.recommended.message,
      };
    case campaignConstants.GET_API_RECOMMENDED_FALIURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function categories(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_CATEGORIES_SUCCESS:
      return {
        items: action.categories.data.categories,
      };
    case campaignConstants.GET_CATEGORIES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function customCat(state = {}, action) {
  switch (action.type) {
    case campaignConstants.GET_CUSTOM_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_CUSTOM_CATEGORIES_SUCCESS:
      return {
        ...state,
        items: action.customCat.data.customCat,
      };
    case campaignConstants.GET_CUSTOM_CATEGORIES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function pivotState(state = {}, action) {
  // console.log("THIS IS THE ACTION IN PIVOTSTATE REDUCER", action);
  switch (action.type) {
    case campaignConstants.SAVE_PIVOTSTATE_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.SAVE_PIVOTSTATE_SUCCESS:
      // console.log("SAVED PIVOTSTATE SUCCESS");
      return {
        ...state,
        items: [action.pivState],
      };
    case campaignConstants.SAVE_PIVOTSTATE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getQueriesById(state = {}, action) {
  // console.log("THIS IS THE ACTION IN QUERIES BY ID REDUCER", action);
  switch (action.type) {
    case campaignConstants.GET_QUERIES_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.GET_QUERIES_BY_ID_SUCCESS:
      // console.log("get queries by id SUCCESS", action);
      return {
        items: action.detail_id.data.queries,
      };

    case campaignConstants.GET_QUERIES_BY_ID_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteQuery(state = {}, action) {
  // console.log("THIS IS THE ACTION INDELETE QUERY", action);
  switch (action.type) {
    case campaignConstants.DELETE_QUERY_REQUEST:
      return {
        loading: true,
      };
    case campaignConstants.DELETE_QUERY_SUCCESS:
      // console.log("DELETE QUERIES SUCCESS", action);
      return {
        items: action,
      };

    case campaignConstants.DELETE_QUERY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
