import { emailConstants } from "../constants";

export function emailLinks(state = {}, action) {
  switch (action.type) {
    case emailConstants.CREATE_EMAIL_LINKS_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.CREATE_EMAIL_LINKS_SUCCESS:
      // console.log('EMAIL LINKS SUCCESS', action);
      return {
        item: action.audience.data,
      };
    case emailConstants.CREATE_EMAIL_LINKS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function createGeneralGroup(state = {}, action) {
  switch (action.type) {
    case emailConstants.CREATE_GENERAL_GROUP_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.CREATE_GENERAL_GROUP_SUCCESS:
      // console.log("CREATE GENERAL GROUP SUCCESS SUCCESS", action);
      return {
        item: action,
      };
    case emailConstants.CREATE_GENERAL_GROUP_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function updateGeneralGroupId(state = {}, action) {
  switch (action.type) {
    case emailConstants.UPDATE_GENERAL_GROUP_ID_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.UPDATE_GENERAL_GROUP_ID_SUCCESS:
      // console.log("UPDATE GENERAL GROUP ID SUCCCESS " , action);
      return {
        item: action,
      };
    case emailConstants.UPDATE_GENERAL_GROUP_ID_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAudience(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_AUDIENCE_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.GET_AUDIENCE_SUCCESS:
      // console.log("get audience success?", action);
      return {
        loading: false,
        item: action.audience.data.audience,
      };
    case emailConstants.GET_AUDIENCE_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getGroups(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_GROUPS_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.GET_GROUPS_SUCCESS:
      // console.log("GET GROUP SUCCCESS", action);
      return {
        item: action.groups.data.groups,
      };
    case emailConstants.GET_GROUPS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function createGroup(state = {}, action) {
  switch (action.type) {
    case emailConstants.CREATE_GROUP_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.CREATE_GROUP_SUCCESS:
      // console.log("CREATE GROUP SUCCESS?", action);
      return {
        item: action.group.data,
      };
    case emailConstants.CREATE_GROUP_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function updateLink(state = {}, action) {
  switch (action.type) {
    case emailConstants.UPDATE_LINK_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.UPDATE_LINK_SUCCESS:
      // console.log("UPDATE LINK SUCCESS", action);
      return {
        item: action,
      };
    case emailConstants.UPDATE_LINK_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function updateSurveySent(state = {}, action) {
  switch (action.type) {
    case emailConstants.SEND_SURVEY_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.SEND_SURVEY_REQUEST_SUCCESS:
      // console.log('send survey success', action);
      return {
        item: action,
      };
    case emailConstants.SEND_SURVEY_REQUEST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getPersonByEmail(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_PERSON_BY_EMAIL_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.GET_PERSON_BY_EMAIL_SUCCESS:
      // console.log("GET PERSON BY EMAIL SUCCESS", action);
      return {
        item: action.email.data.user.email,
      };
    case emailConstants.GET_PERSON_BY_EMAIL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function updatePersonResponse(state = {}, action) {
  switch (action.type) {
    case emailConstants.UPDATE_PERSON_RESPONSE_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.UPDATE_PERSON_RESPONSE_SUCCESS:
      // console.log("UPDATE PERSON RESPONSE SUCCESS", action);
      return {
        item: action,
      };
    case emailConstants.UPDATE_PERSON_RESPONSE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAnswersInGroup(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_GROUP_ANSWERS_REQUEST:
      // console.log("GET ANSWERS IN GROUP REQUEST");
      return {
        loading: true,
      };
    case emailConstants.GET_GROUP_ANSWERS_SUCCESS:
      // console.log("GET ANSWERS IN GROUP SUCCESS?", action);
      return {
        item: action.group.data,
      };
    case emailConstants.GET_GROUP_ANSWERS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAnswersInAllGroups(state = {}, action) {
  switch (action.type) {
    case emailConstants.GET_ANSWERS_IN_ALL_GROUPS_REQUEST:
      // console.log("GET ANSWERS IN ALL GROUP REQUEST");
      return {
        loading: true,
      };
    case emailConstants.GET_ANSWERS_IN_ALL_GROUPS_SUCCESS:
      //    console.log("GET ANSWERS IN ALL GROUPS SUCCESS?", action);
      return {
        item: action,
      };
    case emailConstants.GET_ANSWERS_IN_ALL_GROUPS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saveSurveyRec(state = { rec: [] }, action) {
  switch (action.type) {
    case emailConstants.SAVE_SURVEY_REC_REQUEST:
      return {
        loading: true,
      };
    case emailConstants.SAVE_SURVEY_REC_SUCCESS:
      // console.log('SAVE SURVEY REC SUCCESS?', action);
      return {
        state: action,
      };
    case emailConstants.SAVE_SURVEY_REC_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
