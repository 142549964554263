import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Avatar,
  Grid,
} from "@mui/material";
import {
  Call,
  Schedule,
  Person,
  ChatBubbleOutline,
  Summarize,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

const CallSummary = ({ open, handleClose }) => {
  const { callSummary } = useSelector((state) => ({
    callSummary: state.customCall.callSummary,
  }));

  const call_details = callSummary?.call_details || null;
  const transcripts = callSummary?.transcripts || [];
  const conversation_summary = callSummary?.conversation_summary || null;

  if (!callSummary && callSummary === null) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <Call fontSize="large" color="primary" />
          <Typography variant="h5">Call Details</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* Call Details Section */}
        {call_details && (
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              <Box display="flex" alignItems="center" gap={1}>
                <Schedule color="primary" /> Call Information
              </Box>
            </Typography>
            <Divider sx={{ my: 1 }} />
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Purpose:</strong>
                    </TableCell>
                    <TableCell>{call_details?.purpose || "N/A"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Status:</strong>
                    </TableCell>
                    <TableCell>{call_details?.status || "N/A"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Start Time:</strong>
                    </TableCell>
                    <TableCell>
                      {call_details?.start_time
                        ? new Date(call_details.start_time).toLocaleString()
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>End Time:</strong>
                    </TableCell>
                    <TableCell>
                      {call_details?.end_time
                        ? new Date(call_details.end_time).toLocaleString()
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* Transcripts Section */}
        {transcripts.length > 0 && (
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              <Box display="flex" alignItems="center" gap={1}>
                <ChatBubbleOutline color="primary" /> Transcripts
              </Box>
            </Typography>
            <Divider sx={{ my: 1 }} />
            <List>
              {transcripts.map((transcript) => (
                <ListItem
                  key={transcript.id}
                  alignItems="flex-start"
                  sx={{ py: 1 }}
                >
                  <Avatar sx={{ mr: 2 }}>
                    {transcript?.speaker === "ai" ? <Person /> : <Person />}
                  </Avatar>
                  <ListItemText
                    primary={
                      <strong>
                        {transcript?.speaker?.toUpperCase() || "N/A"}
                      </strong>
                    }
                    secondary={
                      <Typography
                        sx={{ whiteSpace: "pre-wrap" }}
                        variant="body2"
                        color="textSecondary"
                      >
                        {transcript?.transcript_text || "N/A"}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* Conversation Summary Section */}
        {conversation_summary && (
          <Box>
            <Typography variant="h6" gutterBottom>
              <Box display="flex" alignItems="center" gap={1}>
                <Summarize color="primary" /> Conversation Summary
              </Box>
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Total Exchanges:</strong>{" "}
                  {conversation_summary?.total_exchanges || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Duration:</strong>{" "}
                  {conversation_summary?.duration_minutes || "N/A"} minutes
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>AI Summary:</strong>{" "}
                  {conversation_summary?.ai_summary || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  Sentiment Analysis
                </Typography>
                <Typography>
                  <SentimentSatisfiedAlt color="action" />{" "}
                  <strong>Sentiment:</strong>{" "}
                  {conversation_summary?.sentiment_analysis?.sentiment || "N/A"}
                </Typography>
                <Typography>
                  <strong>Reason:</strong>{" "}
                  {conversation_summary?.sentiment_analysis?.reason || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CallSummary;
