import { EMAIL_REGEX, PWD_REGEX, PHONE_REGEX } from "./Constants";
import api from "../../services/api";

const checkEmail = (email) => {
  const isValid = email && EMAIL_REGEX.test(email);
  return isValid;
};

const checkPassword = (pwd) => {
  const isValid = pwd && pwd.match(PWD_REGEX);
  return isValid;
};

const checkPhone = (phone) => {
  const isValid = phone && PHONE_REGEX.test(phone);
  return isValid;
};

const clone = (obj) => {
  if (typeof obj === "object") {
    return JSON.parse(JSON.stringify(obj));
  }
};

const base64Image = (file, callback) => {
  if (file) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.addEventListener("load", function () {
      callback(this.result);
    });
  }
};

const uploadFileToS3 = async (file, callback) => {
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("remote_name", file.name);
    formData.append("object_type", "QUESTION_CHOICE");

    const result = await api.post(`/saveFile`, formData);
    callback(result.data.data.url);
  }
};
const getUser = () => {
  const userStr = localStorage.getItem("user");
  const {
    default_camp_id,
    team_id,
    social_type = "",
    email,
    user_id = "",
    firstName,
    lastName,
    mobile,
    alert_email,
    alert_mobile,
    alert_mobile_carrier,
    alert_mobile_country,
    current_plan,
    calling_id,
    company_number,
    extension_number,
    extension_password,
    international_calling_enabled,
    // smtp_server = "mail.themoveforward.org",
    // smtp_username = "bigimpact@themoveforward.org",
    // smtp_sender_email = "bigimpact@themoveforward.org",
    // smtp_port = 587,
    // smtp_password = "ZGn~pC)5B+c?",
    smtp_server = "",
    smtp_username = "",
    smtp_sender_email = "",
    smtp_port = 0,
    smtp_password = ""

  } = JSON.parse(userStr);
  return {
    default_WS_id: default_camp_id,
    team_id,
    social_type,
    email,
    user_id,
    firstName,
    lastName,
    mobile,
    alert_email,
    alert_mobile,
    alert_mobile_carrier,
    alert_mobile_country,
    current_plan,
    calling_id,
    company_number,
    extension_number,
    extension_password,
    international_calling_enabled,
    smtp_server,
    smtp_username,
    smtp_sender_email,
    smtp_port,
    smtp_password
  };
};

const scrambleEmail = (input) => {
  let scrambled = [];
  for (let i = 0; i < input.length; i++) {
    if (input[i] == "@") {
      scrambled.push("=");
    } else if (input[i] == ".") {
      scrambled.push("=");
    } else {
      scrambled.push(String.fromCharCode(parseInt(input.charCodeAt(i)) + 1));
    }
  }
  return scrambled.join("");
};

const unscrambleEmail = (input) => {
  let scrambled = [];
  let first = 1;
  for (let i = input.length; i >= 0; i--) {
    if (input[i] == null || input[i] == undefined) {
      console.log("null character");
    } else if (input[i] == "=" && (first == 1 || first > 2)) {
      scrambled.push(".");
      first += 1;
    } else if (input[i] == "=" && first == 2) {
      scrambled.push("@");
      first += 1;
    } else {
      scrambled.push(String.fromCharCode(parseInt(input.charCodeAt(i)) - 1));
    }
  }
  return scrambled.reverse().join("");
};

const setLocalStorage = (key, value) => {
  const v = typeof value === "object" ? JSON.stringify(value) : value;
  localStorage.setItem(key, v);
};
const getLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

const themeFormat = (arr) => {
  if (arr && arr.length) {
    return arr.reduce(
      (obj, item) => Object.assign(obj, { [item.theme]: item }),
      {}
    );
  }
  return {};
};

const getWorkspaceID = () => {
  let id = getLocalStorage("workspaceID");
  if (!id) {
    const { default_WS_id } = getUser();
    id = default_WS_id;
  }
  return id;
};

const containsAnyLetters = (str) => {
  return /[a-zA-Z]/.test(str);
};

const isFormType = (type) => {
  return type === "form";
};

const arrObjSorting = (arr, key) => {
  const result = arr.sort((a, b) => {
    let x = a[key].toLowerCase();
    let y = b[key].toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
  return result;
};

const cookieAccepted = () => {
  if (
    window?.cookieyes?._ckyGetFromStore &&
    window.cookieyes._ckyGetFromStore("consent") === "yes"
  ) {
    return true;
  }
  return false;
};

export {
  checkEmail,
  checkPassword,
  checkPhone,
  clone,
  base64Image,
  getUser,
  scrambleEmail,
  unscrambleEmail,
  setLocalStorage,
  getLocalStorage,
  shuffle,
  themeFormat,
  getWorkspaceID,
  containsAnyLetters,
  isFormType,
  arrObjSorting,
  cookieAccepted,
  uploadFileToS3,
};
