import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const LoadingOverlay = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Faded background
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10, // Ensure it appears above the component
}));

const DynamicLoader = ({ show }) => {
  return (
    <Box
      sx={{
        position: "relative", // Ensure the overlay is positioned correctly
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {show && (
        <LoadingOverlay>
          <CircularProgress color="inherit" />
        </LoadingOverlay>
      )}
    </Box>
  );
};

export default DynamicLoader;
