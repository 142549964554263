import { customerEngagementConstant } from "../constants/customerEngagementConstant";
import { CrmService } from "../services";

const CACHE_EXPIRY_TIME = 5 * 60 * 1000;

const caches = {
  customerCache: {},
  summaryCache: {},
  callLogsCache: {},
  emailLogsCache: {},
  feedbackCache: {},
};

const isCacheValid = (cache, key) => {
  const cachedData = cache[key];
  if (!cachedData) return false;

  const currentTime = Date.now();
  return currentTime - cachedData.timestamp < CACHE_EXPIRY_TIME;
};

const fetchWithCache = async (cache, key, fetchFn) => {
  if (isCacheValid(cache, key)) {
    return cache[key].data;
  }

  const data = await fetchFn();
  cache[key] = { data, timestamp: Date.now() };
  return data;
};

const createActionHandlers = (
  requestType,
  successType,
  failureType,
  fetchFn,
  cache
) => {
  return (key) => async (dispatch) => {
    dispatch({ type: requestType });

    try {
      const data = await fetchWithCache(cache, key, () => fetchFn(key));
      dispatch({ type: successType, item: data });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.item?.toString() || "An error occurred";
      dispatch({ type: failureType, item: errorMessage });
    }
  };
};

const getCustomerEngagement = createActionHandlers(
  customerEngagementConstant.GET_CUSTOMER_ENGAGEMENT_REQUEST,
  customerEngagementConstant.GET_CUSTOMER_ENGAGEMENT_SUCCESS,
  customerEngagementConstant.GET_CUSTOMER_ENGAGEMENT_FAILURE,
  CrmService.getCustomerDataById,
  caches.customerCache
);

const getSummaryEngagement = createActionHandlers(
  customerEngagementConstant.GET_SUMMARY_ENGAGEMENT_REQUEST,
  customerEngagementConstant.GET_SUMMARY_ENGAGEMENT_SUCCESS,
  customerEngagementConstant.GET_SUMMARY_ENGAGEMENT_FAILURE,
  CrmService.getSummaryResponse,
  caches.summaryCache
);

const getCallLogs = createActionHandlers(
  customerEngagementConstant.GET_CALL_LOGS_REQUEST,
  customerEngagementConstant.GET_CALL_LOGS_SUCCESS,
  customerEngagementConstant.GET_CALL_LOGS_FAILURE,
  CrmService.getCallsLogsByCustomerId,
  caches.callLogsCache
);

const getEmailLogs = createActionHandlers(
  customerEngagementConstant.GET_EMAIL_LOGS_REQUEST,
  customerEngagementConstant.GET_EMAIL_LOGS_SUCCESS,
  customerEngagementConstant.GET_EMAIL_LOGS_FAILURE,
  CrmService.getEmailsHistory,
  caches.emailLogsCache
);

const getFeedbackResponses = createActionHandlers(
  customerEngagementConstant.GET_FEEDBACK_REQUEST,
  customerEngagementConstant.GET_FEEDBACK_SUCCESS,
  customerEngagementConstant.GET_FEEDBACK_FAILURE,
  CrmService.getFeedbackResponses,
  caches.feedbackCache
);

export const customerEngagementActions = {
  getCustomerEngagement,
  getSummaryEngagement,
  getCallLogs,
  getEmailLogs,
  getFeedbackResponses,
};
