import React from 'react';
import cn from 'classnames';
import Label from './Label';
import '../inputsFields.css';

const ControlledInput = (props) => {
    const { config: { label, name, type, className, labelClass, labelStyle = {}, required, inputClass, inputStyle = {}, title, placeholder, maxLength, minLength, min, max, autoComplete = 'off' }, value, readOnly = false, onChange, onTouchStart, onTouchEnd, onFocus, onBlur, isDisabled, onClick, onKeyPress, isError, error } = props;
    return (<label>
        <Label config={{ label, className: labelClass, style: labelStyle, required }} />
        <div className={className}>
            <input className={cn('inputField', inputClass)} style={inputStyle} type={type || 'text'} name={name} value={value || ''} autoComplete={autoComplete} onChange={onChange} readOnly={readOnly}
                onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onFocus={onFocus} onBlur={onBlur} placeholder={placeholder} disabled={isDisabled} maxLength={maxLength} minLength={minLength} min={min} max={max} onClick={onClick} title={title} onKeyDown={onKeyPress} spellCheck={true} />
            {isError && <div className={cn("invalid-feedback")}>{error}</div>}
        </div>
    </label>)
};

export default ControlledInput;