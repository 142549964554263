export const integrationsConstants = {
    // get all integrations
    GET_ALL_INTEGRATIONS_REQUEST: "GET_ALL_INTEGRATIONS_REQUEST",
    GET_ALL_INTEGRATIONS_SUCCESS: "GET_ALL_INTEGRATIONS_SUCCESS",
    GET_ALL_INTEGRATIONS_FAILURE: "GET_ALL_INTEGRATIONS_FAILURE",
  
    // add integrations
    ADD_INTEGRATIONS_REQUEST: "ADD_INTEGRATIONS_REQUEST",
    ADD_INTEGRATIONS_SUCCESS: "ADD_INTEGRATIONS_SUCCESS",
    ADD_INTEGRATIONS_FAILURE: "ADD_INTEGRATIONS_FAILURE",
    RESET_INTEGRATION_ADDED: "RESET_INTEGRATION_ADDED",
  
    // edit integrations
    EDIT_INTEGRATIONS_REQUEST: "EDIT_INTEGRATIONS_REQUEST",
    EDIT_INTEGRATIONS_SUCCESS: "EDIT_INTEGRATIONS_SUCCESS",
    EDIT_INTEGRATIONS_FAILURE: "EDIT_INTEGRATIONS_FAILURE",
  
    // delete integrations
    DELETE_INTEGRATIONS_REQUEST: "DELETE_INTEGRATIONS_REQUEST",
    DELETE_INTEGRATIONS_SUCCESS: "DELETE_INTEGRATIONS_SUCCESS",
    DELETE_INTEGRATIONS_FAILURE: "DELETE_INTEGRATIONS_FAILURE",
  };
  