import React, { useState } from 'react';
import { Box, Button, Grid, TextField, MenuItem, Typography } from '@mui/material';

const formFields = [
  { label: 'Job Title', name: 'jobTitle', type: 'text', example: 'Examples: marketing manager, content marketing manager' },
  { label: 'Person Location', name: 'personLocation', type: 'text', example: 'Examples: california, ireland, chicago' },
  { label: 'Company Location', name: 'companyLocation', type: 'text', example: 'Examples: texas, tokyo' },
  { label: 'Company Name', name: 'companyName', type: 'text', example: 'Examples: apollo.io, microsoft.com' },
  { label: 'No of Employees', name: 'numEmployees', type: 'select', options: ['1-10', '11-50', '51-200','201-500','501-1000','1001-5000','5001-10000','10000+'] },
  { label: 'No of Data', name: 'numData', type: 'select', options: ['1', '2', '3', '4', '5'] },
  { label: 'Per Page', name: 'perPage', type: 'select', options: ['5', '10', '15', '20'] },
];

const ExternalCustomerForm = ({ onSubmit }) => {
  const [formValues, setFormValues] = useState({
    jobTitle: '',
    personLocation: '',
    companyLocation: '',
    companyName: '',
    numEmployees: '',
    numData: '',
    perPage: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    // const payload = {
    //   person_titles: [formValues.jobTitle],
    //   q_organization_domains: formValues.companyName,
    //   person_locations: formValues.personLocation,
    //   organization_locations: formValues.companyLocation,
    //   organization_num_employees_ranges: [formValues.numEmployees],
    //   page: parseInt(formValues.numData, 10),
    //   per_page: parseInt(formValues.perPage, 10),
    // };

    const payload = {
      api_provider_id : 23,
     
      query_params:{
        person_titles: [formValues.jobTitle],
        q_organization_domains: [formValues.companyName],
        person_locations: [formValues.personLocation],
        organization_locations: [formValues.companyLocation],
        organization_num_employees_ranges: [formValues.numEmployees],
        page: parseInt(formValues.numData, 10),
        per_page: parseInt(formValues.perPage, 10),
      }
  }

    onSubmit(payload);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Search Customer from apollo
      </Typography>
      <Grid container spacing={2} >
        {formFields.map(({ label, name, type, options, example }, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Typography variant="body2" style={{ color: 'lightgrey' }} gutterBottom>
              {example || ''}
            </Typography>
            {type === 'text' ? (
              <TextField
                fullWidth
                label={label}
                name={name}
                variant="outlined"
                value={formValues[name] || ''}
                onChange={handleInputChange}
              />
            ) : (
              <TextField
                select
                fullWidth
                label={label}
                name={name}
                variant="outlined"
                value={formValues[name] || ''}
                onChange={handleInputChange}
              >
                {options.map((option, idx) => (
                  <MenuItem key={idx} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        ))}
      </Grid>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ textTransform: 'none' }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ExternalCustomerForm;
