import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4"; // Analytics library
import { history } from "./helpers"; // Custom history object
import { alertActions } from "./actions"; // Redux actions
import Login from "./voc/login/Login";
import SignUp from "./voc/Signup/Signup";
import TeamRegister from "./voc/teamRegister/TeamRegister";
import ForgetPassword from "./voc/forgetPassword/ForgetPassword";
import TokenVerify from "./voc/forgetPassword/TokenVerify";
import Profile from "./voc/Signup/Profile";
import Confirmation from "./voc/Signup/ConfirmPage";
import AddCustomer from "./voc/CRM/CustomerTable/AddCustomer";
import ViewNotes from "./voc/CRM/CustomerTable/ViewNotes";
import MuiTable from "./voc/Table/CommonCustomerTable";
import VoiceAgentsTable from "./voc/Table/VoiceAgentsTable";
import VoiceRulesTable from "./voc/Table/VoiceRulesTable";
import UserSetting from "./voc/UserSettings/UserSetting";
import CustomerTable from "./voc/CRM/CustomerTable/CustomerTable";
import ScheduledCallsTable from "./voc/CRM/CustomerTable/ScheduledCallsTable";
import EditCustomerDetails from "./voc/CRM/CustomerTable/EditCustomerDetails";
import MoreCustomerDetails from "./voc/CRM/CustomerTable/MoreCustomerDetails";
import VideoCall from "./voc/CRM/CustomerTable/VideoCall";
import IncomingCallModal from "./components/Dialer/IncommingCallModal";
import VocCalendar from "./voc/CRM/Calendar/Calendar";
import CallCenterDashboard from "./components/CallCenter/CallDashboard";
import IntegrationForm from "./voc/CRM/Integration/IntegrationForm";
import StepForm from "./voc/CRM/Integration/StepForm";
import { MainMenu, Header } from "./voc/Common/Header"; // Your MainMenu component
import RoleTable from "./voc/CRM/Roles/RoleTable";
import UsersAndTeams from "./voc/CRM/users/UsersAndTeams";
import TeamTable from "./voc/CRM/Team/TeamTable";

const loading = () => null;

function App() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {
    // Clear alert on location change
    history.listen((location) => {
      dispatch(alertActions.clear());
    });

    const TRACKING_ID = "G-XCGDN5X1FM";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, [dispatch]);

  // Define routes that should not display the MainMenu
  const noSidebarRoutes = [
    "/login",
    "/signup",
    "/TeamRegister",
    "/forgetPassword",
    "/resetpassword",
    "/confirmation",
  ];

  const isNoSidebarRoute =
    !(
      user?.social_type === "outlook" &&
      noSidebarRoutes.includes(location.pathname)
    ) && noSidebarRoutes.includes(location.pathname);  

  return (
    <Router history={history}>
      <IncomingCallModal />
      <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
    {!isNoSidebarRoute && <Header style={{ position: "sticky", top: 0, zIndex: 1000 }}/>}

    <div style={{ display: "flex", flex: 1, overflow: "auto" }}>
      {!isNoSidebarRoute && (
        <div style={{ width: "5%", transition: "width 0.3s ease" }}>
          <MainMenu />
        </div>
      )}

      <div
        style={{
          flexGrow: 1,
          marginLeft: isNoSidebarRoute ? "0" : "0",
          transition: "margin 0.3s ease",
        }}
      >
        <React.Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/TeamRegister" component={TeamRegister} />
            <Route path="/forgetPassword" component={ForgetPassword} />
            <Route path="/resetpassword" component={TokenVerify} />
            <Route path="/confirmation" component={Confirmation} />
            <Route exact path="/" component={CallCenterDashboard} />
            <Route path="/user-setting" component={UserSetting} />
            <Route path="/customerTable" component={CustomerTable} />
            <Route path="/add-customer" component={AddCustomer} />
            <Route path="/scheduled-calls" component={ScheduledCallsTable} />
            <Route path="/viewEdit/:id" component={EditCustomerDetails} />
            <Route path="/muiTable" component={MuiTable} />
            <Route path="/viewNotes/:id" component={ViewNotes} />
            <Route path="/viewAgents" component={VoiceAgentsTable} />
            <Route path="/viewRules" component={VoiceRulesTable} />
            <Route path="/integration" component={IntegrationForm} />
            <Route path="/ExternalCustomer" component={StepForm} />
            <Route path="/calendar" component={VocCalendar} />
            <Route path="/moreCustomerDetails/:id" component={MoreCustomerDetails} />
            <Route path="/videocall/:roomId" component={VideoCall} />
            <Route path="/roles" component={RoleTable} />
            <Route path="/users" component={UsersAndTeams} />
            <Route path="/team" component={TeamTable} />
            <Redirect from="*" to="/" />
          </Switch>
        </React.Suspense>
      </div>
    </div>
  </div>
    </Router>
  );
}

export default App;
