import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import {
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUser, history } from "../../helpers";
import Tooltip from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import InfoModal from "../CRM/CustomerTable/InfoModal";
import Compose from "../CRM/CustomerTable/EmailSetupCustomerTable/Compose";
import DeleteIcon from "@mui/icons-material/Delete";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";


import { crmActions } from "../../actions";
import CallModal from "../CRM/CustomerTable/CallModal";
import { Box, Checkbox, Chip, Stack, Button, useMediaQuery, Typography, Card, CardContent, Collapse, CardActions } from "@mui/material";
import { getBusinessStatus } from "../../helpers/getBizHours";
import { DeleteOutlined } from "@mui/icons-material";
import { customCallActions } from "../../actions/customCallActions";

const CommonTable = ({ dataSource, handleCustomerAdd, handleShow }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [customerId, setCustomerId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [showCompose, setShowCompose] = useState(false);
  const [showCall, setShowCall] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [selectedRowsWithTimezone, setSelectedRowsWithTimezone] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");


  // Select All
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterQuery, setFilterQuery] = useState("");
  const dispatch = useDispatch();
  const [expandedRow, setExpandedRow] = useState(null);
  const theme = useTheme();

  const toggleExpand = (id) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };


  const { customerStatus } = useSelector((store) => ({
    customerStatus: store.customCall.customerStatus,
  }));

  console.log(customerStatus, "<===customerStatus");

  useEffect(() => {
    if (dataSource) {
      // add biz hour status to each customer
      const updatedData = dataSource.map((item) => {
        return {
          ...item,
          biz: getBusinessStatus(item.timezone),
        };
      });

      setData(updatedData);
    }
  }, [dataSource]);

  const sortData = (field) => {
    setSortField(field);
    const sortedData = [...data].sort((a, b) => {
      if (a[field] < b[field]) return sortOrder === "asc" ? -1 : 1;
      if (a[field] > b[field]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortBizHours = (field) => {
    const sortedData = [...data].sort((a, b) => {
      if (a.biz.status === field) return -1;
      if (b.biz.status === field) return 1;
      return 0;
    });
    setData(sortedData);
  };

  const filterData = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    setCurrentPage(1);
  };

  const filteredData = data.filter(
    (item) =>
      item.first_name.toLowerCase().includes(filterQuery) ||
      item.email.toLowerCase().includes(filterQuery) ||
      item.country.toLowerCase().includes(filterQuery) ||
      item.company.toLowerCase().includes(filterQuery) ||
      item.title.toLowerCase().includes(filterQuery)
  );

  // Pagination calculations
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstItemIndex, lastItemIndex);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Create pagination items
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10;
    const halfPageNumbers = Math.floor(maxPageNumbers / 2);

    let startPage = Math.max(currentPage - halfPageNumbers, 1);
    let endPage = Math.min(currentPage + halfPageNumbers, totalPages);

    if (startPage <= halfPageNumbers) {
      endPage = Math.min(maxPageNumbers, totalPages);
    }
    if (endPage >= totalPages - halfPageNumbers) {
      startPage = Math.max(totalPages - maxPageNumbers + 1, 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      pageNumbers.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
      pageNumbers.unshift(
        <Pagination.Item key={1} onClick={() => paginate(1)}>
          1
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" />);
      pageNumbers.push(
        <Pagination.Item key={totalPages} onClick={() => paginate(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      // Select all current page items
      const allSelected = currentItems.map((item) => item.id);
      setSelectedRows(allSelected);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(id);

      if (isSelected) {
        // Deselect the row
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        // Select the row
        return [...prevSelectedRows, id];
      }
    });
  };

  const getTimezoneOffset = (timezone) => {
    try {
      if (!timezone) throw new Error("Invalid or missing timezone");
      const date = new Date();
      const utcDate = new Date(
        date.toLocaleString("en-US", { timeZone: "UTC" })
      );
      const localDate = new Date(
        date.toLocaleString("en-US", { timeZone: timezone })
      );
      const diff = localDate - utcDate;
      return diff / (1000 * 60 * 60); // Convert to hours
    } catch (error) {
      console.error("Error calculating timezone offset:", error.message);
      return 0; // Fallback to 0 offset if timezone is invalid
    }
  };

  useEffect(() => {
    // Filter and map selected rows
    const updatedData = currentItems
      .filter((item) => selectedRows.includes(item.id)) // Only include selected rows
      .map((item) => [
        item.id,
        getTimezoneOffset(item.timezone || "UTC"), // Use "UTC" as fallback timezone
      ]);

    // Update state only if data or selection state changes
    if (
      JSON.stringify(updatedData) !== JSON.stringify(selectedData) || // Check if data has changed
      selectAll !==
      (currentItems.length > 0 &&
        currentItems.every((item) => selectedRows.includes(item.id)))
    ) {
      setSelectedData(updatedData); // Store in selectedData
      setSelectAll(
        currentItems.length > 0 &&
        currentItems.every((item) => selectedRows.includes(item.id))
      );
    }
  }, [selectedRows, currentItems, selectedData, selectAll]);

  function sendSingleEmail(id, timezone) {
    console.log("sendSingleEmail", id);
    const timeZone = getTimezoneOffset(timezone);
    setSelectedRows([id, timeZone]);
    handleShowEmail();
  }

  // ----------------------- GET BUSINESS HOURS ----------------------- //

  const handleView = (id) => {
    console.log(id, "<===id");
    history.push(`/viewEdit/${id}`); // Adjust the route as needed
  };

  // Function to navigate to the edit page
  const handleEdit = (id) => {
    console.log(id, "<==id");
    history.push(`/viewNotes/${id}`);
  };

  const handleShowEmail = (id) => {
    console.log(id, "<===item herer"); // Log the email
    setCustomerId(id);
    setShowCompose(true);
  };

  const handleShowCall = (id, first_phone) => {
    console.log("handleShowCall", id);
    setCustomerId(id);
    setPhoneNumber(first_phone);
    setShowCall(true);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const handleCloseCompose = () => {
    setShowCompose(false);
  };

  const handleRedirect = (id) => {
    history.push(`/moreCustomerDetails/${id}`);
  };

  const handleShowInfoModal = (customer, event) => {
    const rect = event.target.getBoundingClientRect();
    setCursorPosition({
      x: rect.right + window.scrollX,
      y: rect.top + window.scrollY,
    });
    setSelectedCustomer(customer);
    setShowInfoModal(true);
  };

  const deleteCustomer = (Id) => {
    console.log(Id);
    console.log("delete Note trigger");
    dispatch(crmActions.deleteCustomer(Id));
    const userDetails = getUser();
    dispatch(crmActions.getAllCustomers(userDetails.user_id));
  };

  const badgeColor = {
    lunch: "warning",
    within: "success",
    outside: "error",
  };


  return (
    <div style={{ margin: "20px auto", border: "0px solid red" }}>
      <Form.Group className="mb-3" controlId="filterQuery">
        <Form.Control
          type="text"
          placeholder="Search by name or email..."
          onChange={filterData}
        />
      </Form.Group>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 2,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            pb: 2,
            px: 2,
          }}
        >
          <DropdownButton id="dropdown-basic-button" title="Sort By" size="sm">
            <Dropdown.Item onClick={() => sortData("first_name")}>
              First Name
            </Dropdown.Item>
            <Dropdown.Item onClick={() => sortData("last_name")}>
              Last Name
            </Dropdown.Item>
            <Dropdown.Item onClick={() => sortData("email")}>
              Email
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setOpenFilter(true)}>
              Biz Hour
            </Dropdown.Item>
          </DropdownButton>

          {openFilter && (
            <DropdownButton
              id="dropdown-basic-button"
              title="Sort By Biz Hours"
              size="sm"
            >
              <Dropdown.Item onClick={() => sortBizHours("within")}>
                Active
              </Dropdown.Item>
              <Dropdown.Item onClick={() => sortBizHours("outside")}>
                Away
              </Dropdown.Item>
            </DropdownButton>
          )}
        </Box>

        {selectedRows.length > 0 && (
          <Stack direction="row" spacing={2}>
            <Button
              size="sm"
              color="primary"
              variant="contained"
              startIcon={<MailOutlinedIcon />}
              onClick={handleShowEmail}
            >
              Compose
            </Button>
          </Stack>
        )}
      </Box>

      {isMobile ? (
         <div>
         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
           <Checkbox
             checked={selectAll}
             onChange={handleSelectAll}
             style={{ marginRight: '8px' }} // Spacing between checkbox and label
           />
           <label style={{ fontSize: '14px', color: '#333' }}>Select all</label>
         </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'center' }}>
          {currentItems.map((row) => (
            <Card
              key={row.id}
              sx={{
                width: '90%',
                maxWidth: 400,
                marginBottom: 2,
                boxShadow: 3,
                borderRadius: '16px',
                overflow: 'hidden',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.03)',
                },
              }}
            >
              <CardContent
                sx={{
                  backgroundColor: '#f9f9f9',
                  padding: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div>
                  <Typography variant="h6" fontWeight="bold" color="primary">
                    {row.first_name} {row.last_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {row.company}
                  </Typography>
                  <Typography>
                    Biz Hours:{' '}
                    <Chip
                      label={getBusinessStatus(row.timezone)?.message}
                      color={badgeColor[getBusinessStatus(row.timezone)?.status]}
                      variant="outlined"
                      sx={{ fontWeight: 'bold' }}
                    />
                  </Typography>
                </div>
                <Checkbox
                  checked={selectedRows.includes(row.id)}
                  onChange={() => handleRowSelect(row.id)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                  }}
                />
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between', padding: '0 16px' }}>
                <Button
                  size="small"
                  onClick={() => toggleExpand(row.id)}
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#0056b3',
                    },
                  }}
                >
                  {expandedRow === row.id ? 'Collapse' : 'View Details'}
                </Button>
              </CardActions>
              <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
                <CardContent sx={{ padding: '16px', backgroundColor: '#f3f3f3' }}>
                  <Typography>Email: {row.email}</Typography>
                  <Typography>Title: {row.title}</Typography>
                  <Typography>Time Zone: {row.timezone}</Typography>
                  {/* <Typography>
                    Biz Hours:{' '}
                    <Chip
                      label={getBusinessStatus(row.timezone)?.message}
                      color={badgeColor[getBusinessStatus(row.timezone)?.status]}
                      variant="outlined"
                      sx={{ fontWeight: 'bold' }}
                    />
                  </Typography> */}
                  <div style={{ display: 'flex', gap: '8px', marginTop: '10px', flexWrap: 'wrap' }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handleView(row.id)}
                      sx={{ textTransform: 'none' }}
                    >
                      View/Edit
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        dispatch(
                          customCallActions.makeCall(
                            row.first_phone || row.mobile_phone,
                            row.country
                          )
                        )
                      }
                      sx={{ textTransform: 'none' }}
                    >
                      Call
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={() => handleEdit(row.id)}
                      sx={{ textTransform: 'none' }}
                    >
                      View Notes
                    </Button>
                    <Tooltip title="Send Email">
                      <IconButton
                        onClick={() => sendSingleEmail(row.id, row.timezone)}
                        color="info"
                      >
                        <MailOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="More details">
                      <IconButton onClick={() => handleRedirect(row.id)} color="default">
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => deleteCustomer(row.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </div>
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ padding: ".2rem !important" }}
                >
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                </TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Time Zone</TableCell>
                <TableCell>Biz Hours</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((row) => (
                <TableRow
                  key={row.first_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "0rem !important" }}
                  >
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleRowSelect(row.id)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.first_name}
                  </TableCell>
                  <TableCell>{row.last_name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.company}</TableCell>
                  <TableCell>{row.timezone}</TableCell>
                  <TableCell>
                    <Chip
                      label={getBusinessStatus(row.timezone)?.message}
                      color={badgeColor[getBusinessStatus(row.timezone)?.status]}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          flexShrink: 0,
                          gap: "4px",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={() => handleView(row.id)}
                        >
                          View/Edit
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={() =>
                            dispatch(customCallActions.makeCall(
                              row.first_phone || row.mobile_phone,
                              row.country
                            ))
                          }
                        >
                          Call
                        </Button>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={() => handleEdit(row?.id)}
                        >
                          View Notes
                        </Button>
                      </div>
                      <div
                        style={{
                          border: "1px solid gray",
                          borderRadius: "4px",
                          padding: "4px 6px",
                          margin: "0px 4px",
                          cursor: "pointer",
                        }}
                        onClick={() => sendSingleEmail(row.id, row.timezone)}
                      >
                        <MailOutlinedIcon />
                      </div>
                      <Tooltip title="More details">
                        <IconButton
                          style={{
                            border: "1px solid gray",
                            borderRadius: "4px",
                            padding: "4px 6px",
                            margin: "0px 4px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRedirect(row.id)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          style={{
                            border: "1px solid gray",
                            borderRadius: "4px",
                            padding: "4px 6px",
                            margin: "0px 4px",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteCustomer(row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>)}
      <Pagination>
        <Pagination.Prev
          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
        />
        {renderPageNumbers()}
        <Pagination.Next
          onClick={() =>
            paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
          }
        />
      </Pagination>
      {showCompose && (
        <Compose
          customerId={customerId}
          show={showCompose}
          handleClose={handleCloseCompose}
          isSchedule={true}
          customers={selectedData}
        />
      )}
      {showCall && (
        <CallModal
          isVisible={showCall}
          customerId={customerId}
          phoneNumber={phoneNumber}
          onClose={() => setShowCall(false)}
        />
      )}
      <InfoModal
        show={showInfoModal}
        handleClose={handleCloseInfoModal}
        customer={selectedCustomer}
        position={cursorPosition}
      />
    </div>
  );
};

export default CommonTable;
