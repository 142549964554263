import Swal from "sweetalert2";
import { customCallConstant } from "../constants/customCallConstant";
import { formatDate } from "../helpers/dateFormat";
import { CrmService } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";

// Usage
const date = new Date();
const formattedDate = formatDate(date);
console.log(formattedDate); // Outputs: '2025-01-02 20:43:30'

let callEndTime;

function registerSIP(ua, message, status) {

  if (status === "registration_failed") {

    console.log(message, "msggg")

    Swal.fire({
      icon: "error",
      title: "SIP Registration failed",
      text: message,
      showConfirmButton: true,
    });
  }


  return { type: customCallConstant.REGISTER_SIP, payload: { ua, sipMsg: message, sipStatus: status } };
}

function getSession(session) {
  console.log(session, "session");
  return { type: customCallConstant.GET_SESSION, payload: session };
}

function getAudioRef(audioRef) {
  return { type: customCallConstant.GET_AUDIO_REF, payload: audioRef };
}

function makeCall(number, country) {
  console.log(number, country, "numbercountry");
  return (dispatch, getState) => {
    const state = getState();
    const { ua } = state.customCall;

    const options = {
      mediaConstraints: { audio: true, video: false },
    };

    let prefix = "";

    if (country === "IN") {
      prefix = "011";
    } else if (country === "US") {
      prefix = "1";
    }

    if (ua) {
      ua.call(`sip:${prefix}${number}@pbx.articence.com`, options);
      dispatch({ type: customCallConstant.MAKE_CALL, payload: number });
    }
  };
}

function setIncommingCall(call, callType) {
  const callerNumber = call.remote_identity.uri.user;
  return {
    type: customCallConstant.SET_INCOMING_CALL,
    payload: { call, callerNumber, callType },
  };
}

function muteCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      incomingCall.mute({
        audio: true,
        video: false,
      });
      dispatch({ type: customCallConstant.MUTE_CALL });
    } else {
      console.error("No incoming call to mute");
    }
  };
}

function unMuteCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      incomingCall.unmute({
        audio: true,
        video: false,
      });
      dispatch({ type: customCallConstant.UNMUTE_CALL });
    } else {
      console.error("No incoming call to mute");
    }
  };
}

function acceptCallRequest() {
  return { type: customCallConstant.ACCEPT_CALL };
}

function holdCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const incomingCall = state?.customCall?.incomingCall;

    if (!incomingCall) {
      console.error("No active call to hold.");
      return;
    }

    try {
      console.log("Attempting to hold the call...");
      incomingCall.hold({
        useUpdate: false,
      });
      dispatch({ type: customCallConstant.HOLD_CALL });
    } catch (error) {
      console.error("Error while holding the call:", error);
    }
  };
}

function unHoldCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const incomingCall = state?.customCall?.incomingCall;

    if (!incomingCall) {
      console.error("No active call to unhold.");
      return;
    }

    try {
      console.log("Attempting to unhold the call...");
      incomingCall.unhold({
        useUpdate: false,
      });

      dispatch({ type: customCallConstant.UNHOLD_CALL });
    } catch (error) {
      console.error("Error while unholding the call:", error);
    }
  };
}

function transferCallRequest(target) {
  return (dispatch, getState) => {
    const state = getState();

    try {
      const { incomingCall } = state.customCall;
      if (incomingCall) {
        incomingCall.refer(target);
        dispatch({ type: customCallConstant.TRANSFER_CALL });
      }
    } catch {
      console.error("Error while transferring the call");
    }
  };
}

function endCallRequest() {
  return (dispatch) => {
    dispatch({ type: customCallConstant.END_CALL });
  };
}

function progressCall() {
  return { type: customCallConstant.PROGRESS_CALL };
}

function declineCallRequest() {
  return (dispatch, getState) => {
    const state = getState();

    try {
      const { incomingCall } = state.customCall;
      if (incomingCall) {
        incomingCall.terminate();
        dispatch({ type: customCallConstant.DECLINE_CALL });
      }
    } catch (e) {
      dispatch({ type: customCallConstant.DECLINE_CALL });
      console.error("Error while declining the call", e);
    }
  };
}

function startCaptureVoice() {
  console.log("Voice capture started");

  const start_time = new Date().toISOString();

  return async (dispatch, getState) => {
    const state = getState();
    const { session } = state.customCall;

    const sessionRef = session?.current?.connection;

    if (sessionRef) {
      const remoteStream = new MediaStream();

      sessionRef.getReceivers().forEach((receiver) => {
        if (receiver.track && receiver.track.kind === "audio") {
          remoteStream.addTrack(receiver.track);
        }
      });

      if (remoteStream.getTracks().length === 0) {
        console.warn("No audio tracks available for recording.");
        return;
      }

      try {
        const remoteMediaRecorder = new MediaRecorder(remoteStream, {
          mimeType: "audio/webm",
        });

        const audioChunks = [];
        remoteMediaRecorder.ondataavailable = (event) => {
          if (event.data && event.data.size > 0) {
            audioChunks.push(event.data);
          }
        };

        remoteMediaRecorder.start(1000); // Record chunks every 1 second

        // Save MediaRecorder and audioChunks to session
        session.current.mediaRecorder = remoteMediaRecorder;
        session.current.audioChunks = audioChunks;

        console.log("MediaRecorder started:", remoteMediaRecorder);

        dispatch({ type: customCallConstant.START_CAPTURE_VOICE, payload: start_time });
      } catch (error) {
        console.error("Failed to start MediaRecorder:", error);
      }
    } else {
      console.error("No session connection available for capturing voice.");
    }
  };
}

function stopCaptureVoice() {
  console.log("Stopping voice capture");
  return async (dispatch, getState) => {
    const state = getState();
    const { session } = state.customCall;
    const { customerVoiceCaptureStartTime } = state.customCall;
    if (session?.current?.mediaRecorder) {
      const mediaRecorder = session.current.mediaRecorder;
      const audioChunks = session.current.audioChunks;

      if (mediaRecorder.state !== "inactive") {
        return new Promise((resolve, reject) => {
          mediaRecorder.onstop = () => {
            console.log("MediaRecorder stopped");

            if (audioChunks.length === 0) {
              console.error("No audio chunks recorded.");
              reject(new Error("No audio chunks recorded"));
              return;
            }

            // Combine audio chunks into a single Blob
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });

            // Clear MediaRecorder and chunks from session
            session.current.mediaRecorder = null;
            session.current.audioChunks = null;

            resolve(audioBlob, customerVoiceCaptureStartTime);
          };

          // Stop the MediaRecorder
          mediaRecorder.stop();
          dispatch({ type: customCallConstant.STOP_CAPTURE_VOICE });
          console.log("Stopping MediaRecorder...");
        });
      } else {
        console.warn("MediaRecorder is not active.");
      }
    } else {
      console.error("MediaRecorder not found in session.");
    }
  };
}

function setTranscription(data) {
  return {
    type: customCallConstant.TRANSCRIPTION,
    payload: {
      transcription: data.generated_response,
      message: data.transcript,
    },
  };
}


function captureAgentVoice(mediaRef, audioChunkRef) {
  return async (dispatch) => {
    console.log("Started capturing agent voice");

    const start_time = new Date().toISOString();

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm", // Ensure consistency with startCaptureVoice
      });

      mediaRef.current = mediaRecorder;
      audioChunkRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunkRef.current.push(event.data);
        }
      };

      mediaRecorder.start(1000); // Record chunks every 1 second

      dispatch({
        type: customCallConstant.CAPTURE_AGENT_VOICE,
        payload: start_time,
      });
    } catch (error) {
      console.error("Error capturing agent voice:", error);
    }
  };
}

const stopAgentVoice = (mediaRef, audioChunkRef, callLogId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { agentVoiceCaptureStartTime } = state.customCall;

    if (mediaRef.current) {
      return new Promise((resolve, reject) => {
        mediaRef.current.onstop = () => {
          const agentAudioBlob = new Blob(audioChunkRef.current, {
            type: "audio/webm",
          });
          console.log("Recording stopped, audio blob created:", agentAudioBlob);
          audioChunkRef.current = [];
          resolve(agentAudioBlob);
        };
        mediaRef.current.stop();
        console.log("Stopping MediaRecorder...");
      }).then(async (agentAudioBlob) => {
        try {
          const formData = new FormData();
          formData.append("file", agentAudioBlob, "recording.webm");
          formData.append("call_log_id", callLogId);
          formData.append("start_time", agentVoiceCaptureStartTime);
          formData.append("speaker", "agent");
          const audioUrl = await CrmService.uploadAudio(formData);
          dispatch(setTranscription(audioUrl.data));
        } catch (e) {
          console.error("Error uploading audio:", e);
        }
      })
    } else {
      console.error("MediaRecorder not found for agent voice.");
    }
  };
};


function getStartTime() {
  return async (dispatch) => {
    const date = new Date();
    const time = formatDate(date);
    dispatch({ type: customCallConstant.GET_CALL_START_TIME, payload: time });
  };
}

function StoreCallLogs(customerId, callType, callStatus, firstPhone) {
  return async (dispatch) => {
    const callStartTime = formatDate(new Date());

    const payload = {
      phone_number: firstPhone,
      call_start_time: callStartTime,
      call_end_time: callStartTime,
      call_status: callStatus,
      call_duration: 0,
      recording: true,
      call_type: callType,
      customer_id: customerId,
    };

    try {
      const response = await CrmService.createCallLogs(payload);
      const callLogId = response.data.call_log_id;

      dispatch({
        type: customCallConstant.STORE_CALL_LOGS,
        payload: { callLogId, firstPhone },
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function updateCallLogs(callStatus) {
  return async (dispatch, getState) => {
    const state = getState();

    const { callStartTime } = state.customCall;

    try {
      const callLogId = state.customCall.callLogId;
      const firstPhone = state.customCall.firstPhone;

      callEndTime = formatDate(new Date());

      const duration = new Date(callEndTime) - new Date(callStartTime);
      const callDuration = duration / 1000;

      const payload = {
        phone_number: firstPhone,
        call_status: callStatus,
        call_duration: callDuration,
        call_end_time: callEndTime,
      };

      if (callStartTime !== null) {
        payload.call_start_time = callStartTime;
      }
      CrmService.updateCallLogs(payload, callLogId);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: customCallConstant.GET_CALL_START_TIME, payload: null });
    }
  };
}

function getAllCallLogsWithFilter(filter) {
  return async (dispatch, getState) => {
    try {
      const response = await CrmService.getAllCallLogsWithFilter(filter);
      console.log(response);
      dispatch({
        type: customCallConstant.GET_CALL_LOGS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function getAllEmailLogsWithFilter(filter) {
  return async (dispatch, getState) => {
    try {
      const response = await CrmService.getAllEmailsHistory(filter);
      console.log(response);
      dispatch({
        type: customCallConstant.GET_ALL_EMAILS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function getCallDashboardData(filter) {
  return async (dispatch, getState) => {
    try {
      const response = await CrmService.getDashboardData(filter);
      console.log(response);
      dispatch({
        type: customCallConstant.GET_DASHBOARD_DATA,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function getCustomerStatus(status) {
  return { type: customCallConstant.GET_CUSTOMER_STATUS, payload: status };
}

function getCustomerData(id) {
  return async (dispatch, getState) => {
    try {
      const response = await CrmService.getCustomerDataById(id);
      console.log(response);
      dispatch({
        type: customCallConstant.GET_CUSTOMER_DATA,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function getCallSummary(callId) {
  const request = () => ({
    type: customCallConstant.GET_CALL_SUMMARY,
  });

  const success = (callSummary) => ({
    type: customCallConstant.GET_CALL_SUMMARY_SUCCESS,
    callSummary: callSummary,
  });

  const failure = () => ({
    type: customCallConstant.GET_CALL_SUMMARY_FAILURE,
  });

  return (dispatch) => {
    dispatch(request());

    CrmService.getCallSummary(callId).then(
      (data) => {
        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure());
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
}

export const customCallActions = {
  registerSIP,
  makeCall,
  getAudioRef,
  setIncommingCall,
  acceptCallRequest,
  holdCallRequest,
  unHoldCallRequest,
  transferCallRequest,
  progressCall,
  declineCallRequest,
  endCallRequest,
  muteCallRequest,
  StoreCallLogs,
  updateCallLogs,
  unMuteCallRequest,
  startCaptureVoice,
  stopCaptureVoice,
  setTranscription,
  getSession,
  getAllCallLogsWithFilter,
  getCallDashboardData,
  getCustomerStatus,
  getCustomerData,
  getAllEmailLogsWithFilter,
  getCallSummary,
  getStartTime,
  captureAgentVoice,
  stopAgentVoice
};
