export const LoadingPopup = ({ visible }) => {
    if (!visible) return null;
  
    return (
      <div style={{ 
        position: 'fixed', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        backgroundColor: 'rgba(0, 0, 0, 0.75)', 
        color: '#fff', 
        padding: '20px', 
        borderRadius: '8px', 
        textAlign: 'center',
        zIndex: 1000 ,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div style={{ marginBottom: '10px' }}>
          {/* Spinner */}
          <div className="spinner" style={{
            width: '40px',
            height: '40px',
            border: '4px solid #fff',
            borderTop: '4px solid transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
          }}></div>
        </div>
      </div>
    );
  };
  
  // CSS for Spinner Animation
  const styles = document.createElement('style');
  styles.innerHTML = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(styles);