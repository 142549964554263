import React, { useState } from 'react';
import { Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';
import InviteTeamPopup from './InviteTeamPopup';

const UsersAndTeams = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const currentUserData = [
        { user: 'You', role: 'Admin', creditLimit: 'No Credit Limit' },
        { user: 'Admin User', role: '--', creditLimit: 'No Credit Limit' },
    ];

    const pendingUserData = [
        { name: 'John Doe', email: 'john.doe@example.com', source: 'Manual' },
        { name: 'Jane Smith', email: 'jane.smith@example.com', source: 'Import' },
    ];

    return (
        <div style={{ padding: '16px', flexGrow: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '16px' }}>
                {/* Responsive Tabs */}
                <div style={{ flex: '0 0 40%' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ borderRadius: '4px', textTransform: 'capitalize' }}
                    >
                        <Tab label="Current User" style={{ textTransform: 'capitalize' }} />
                        <Tab label="Pending User" style={{ textTransform: 'capitalize' }} />
                    </Tabs>
                </div>

                {/* Action Button */}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={openPopup}
                >
                    Invite Users
                </Button>
            </div>

            <Paper style={{ marginTop: '16px' }}>
                {activeTab === 0 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Credit Limit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentUserData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.user}</TableCell>
                                        <TableCell>{row.role}</TableCell>
                                        <TableCell>{row.creditLimit}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {activeTab === 1 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Source</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingUserData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.source}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="success" size="small" style={{ marginRight: '8px' }}>
                                                Approve
                                            </Button>
                                            <Button variant="contained" color="error" size="small">
                                                Reject
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>

            {isPopupOpen && <InviteTeamPopup onClose={closePopup} />}
        </div>
    );
};

export default UsersAndTeams;