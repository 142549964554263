import React, { useEffect } from "react";
import { Box, Typography, Slide } from "@mui/material";

const AnimatedStatus = ({ message, show, onClose }) => {

    const [showStatus, setShow] = React.useState(show);

    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                setShow(false)
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [show, onClose])


    return (
        <Slide direction="down" in={showStatus} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#4caf50", // Green for success
                    color: "#fff",
                    textAlign: "center",
                    padding: "15px",
                    zIndex: 1300,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
            >
                <Typography variant="body2">{message}</Typography>
            </Box>
        </Slide>
    );
};

export default AnimatedStatus;
