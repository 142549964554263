import { useEffect, useState, useRef } from "react";
import { crmActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Common/Utils";
import { Header, MainMenu } from "../../Common/Header";
import ScheduledCallCustomerTable from "./ScheduledCallCustomerTable";
import HeaderListModal from "./HeaderListModal";

const ScheduledCallsTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setfilteredCustomers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const userDetails = getUser();
    dispatch(crmActions.getAllScheduledCalls(userDetails.user_id));
  }, []);

  const customerData = useSelector((state) => state?.crmLogic);

  useEffect(() => {
    if (customerData) {
      const newTableData = customerData?.message?.data?.calls;
      setAllCustomers(newTableData);
      setfilteredCustomers(newTableData);
    } else {
      console.log("Data not yet available or path incorrect");
    }
  }, [customerData]);

  return (
    <div>
      {/* <Header /> */}
      {/* <MainMenu /> */}
      <HeaderListModal
        isVisible={showHeaderModal}
        onClose={() => setShowHeaderModal(false)}
      />

      <div
        style={{
          margin: "20px auto",
          border: "0px solid red",
        }}
      >
        <ScheduledCallCustomerTable
          dataSource={searchTerm.length ? filteredCustomers : allCustomers}
        />
      </div>
    </div>
  );
};

export default ScheduledCallsTable;
