import React, { useState } from 'react'; 
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Button, Typography } from '@mui/material';

const formFields = [
  { label: 'Job Title', name: 'jobTitle', type: 'text' },
  { label: 'Person Location', name: 'personLocation', type: 'text' },
  { label: 'Company Location', name: 'companyLocation', type: 'text' },
  { label: 'Company Name', name: 'companyName', type: 'text' },
  { label: 'No of Employees', name: 'numEmployees', type: 'select', options: ['1-10', '11-50', '51-200'] },
  { label: 'No of Data', name: 'numData', type: 'select', options: ['1', '2', '3', '4', '5'] },
  { label: 'Per Page', name: 'perPage', type: 'select', options: ['5', '10', '15', '20'] },
];

const ApolloCustomerModal = ({ show, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    jobTitle: '',
    personLocation: '',
    companyLocation: '',
    companyName: '',
    numEmployees: '',
    numData: '',
    perPage: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const payload = {
      person_titles: [formValues.jobTitle],
      q_organization_domains: formValues.companyName,
      person_locations: formValues.personLocation,
      organization_locations: formValues.companyLocation,
      organization_num_employees_ranges: [formValues.numEmployees],
      page: parseInt(formValues.numData, 10),
      per_page: parseInt(formValues.perPage, 10),
    };

    onSubmit(payload);
    onClose();
  };

  return (
    <Dialog open={show} onClose={onClose} fullWidth>
      <DialogTitle>Apollo Customer Filter</DialogTitle>
      <DialogContent>
        <Typography variant="body2" style={{ color: 'lightgrey' }} gutterBottom>
          Examples: marketing manager, content marketing manager
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Job Title"
          name="jobTitle"
          value={formValues.jobTitle || ''}
          onChange={handleInputChange}
        />
        <Typography variant="body2" style={{ color: 'lightgrey' }} gutterBottom>
          Examples: california, ireland, chicago
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Person Location"
          name="personLocation"
          value={formValues.personLocation || ''}
          onChange={handleInputChange}
        />
        <Typography variant="body2" style={{ color: 'lightgrey' }} gutterBottom>
          Examples: texas, tokyo
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Company Location"
          name="companyLocation"
          value={formValues.companyLocation || ''}
          onChange={handleInputChange}
        />
        <Typography variant="body2" style={{ color: 'lightgrey' }} gutterBottom>
          Examples: apollo.io, microsoft.com
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Company Domain"
          name="companyName"
          value={formValues.companyName || ''}
          onChange={handleInputChange}
        />
        {formFields.slice(4).map(({ label, name, type, options }, index) => (
          type === 'text' ? (
            <TextField
              key={index}
              fullWidth
              margin="normal"
              label={label}
              name={name}
              value={formValues[name] || ''}
              onChange={handleInputChange}
            />
          ) : (
            <TextField
              key={index}
              select
              fullWidth
              margin="normal"
              label={label}
              name={name}
              value={formValues[name] || ''}
              onChange={handleInputChange}
            >
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApolloCustomerModal;
