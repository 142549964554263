import { integrationsConstants } from '../constants/integrationConstants';

const initialState = {
  loading: false,
  isIntegrationAdded: false,
  integrations: [],
  message: "",
};

export function integrationLogic(state = initialState, action) {
  switch (action.type) {
    case integrationsConstants.ADD_INTEGRATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case integrationsConstants.ADD_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isIntegrationAdded: true, // Set success flag to true
        integrations: [...state.integrations, action.integrations], // Optional: update agents list in state if needed
      };
    case integrationsConstants.ADD_INTEGRATIONS_FAILURE:
      return {
        ...state,
        loading: false, 
        message: action.error,
        type: "error",
      };
      case integrationsConstants.RESET_INTEGRATION_ADDED:
  return {
    ...state,
    isIntegrationAdded: false,
  };

    default:
      return state;
  }
}
