import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, TextField } from '@mui/material';


const InviteTeamPopup = ({ onClose }) => {
  const [permissionProfile, setPermissionProfile] = useState('');
  const [emails, setEmails] = useState('');

  const handleInvite = () => {
    // Add your invite logic here
    console.log('Inviting with:', { permissionProfile, emails });
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Invite your Team</DialogTitle>
      <DialogContent style={{paddingTop: "10px"}}>
        {/* Dropdown for Permission Profile */}
        <TextField
          select
          label="Set permission profile"
          fullWidth
          value={permissionProfile}
          onChange={(e) => setPermissionProfile(e.target.value)}
          style={{ marginBottom: '16px' }}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="non-admin">Manager</MenuItem>
          <MenuItem value="non-admin">User</MenuItem>
        </TextField>

        {/* Text Area for Emails */}
        <TextField
          label="Enter emails of users"
          multiline
          rows={4}
          fullWidth
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          placeholder="Enter emails separated by commas"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleInvite} color="primary" variant="contained">
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteTeamPopup;
