import { customerEngagementConstant } from "../constants/customerEngagementConstant";

export function customerEngagementLogic(state = {}, action) {
  switch (action.type) {
    case customerEngagementConstant.GET_CUSTOMER_ENGAGEMENT_REQUEST:
      return {
        ...state,
        customerLoading: true,
        message: "Fetching customer data",
      };

    case customerEngagementConstant.GET_CUSTOMER_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        customerLoading: false,
        customer: action?.item,
        type: "success",
        message: "Customer data fetched successfully",
      };

    case customerEngagementConstant.GET_CUSTOMER_ENGAGEMENT_FAILURE:
      return {
        ...state,
        customerLoading: false,
        message: "Failed to fetch customer data",
        type: "error",
      };

    case customerEngagementConstant.GET_SUMMARY_ENGAGEMENT_REQUEST:
      return {
        ...state,
        summaryLoading: true,
        message: "Fetching summary data",
      };

    case customerEngagementConstant.GET_SUMMARY_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        summaryLoading: false,
        summary: action?.item,
        type: "success",
        message: "Summary data fetched successfully",
      };

    case customerEngagementConstant.GET_SUMMARY_ENGAGEMENT_FAILURE:
      return {
        ...state,
        summaryLoading: false,
        message: "Failed to fetch summary data",
        type: "error",
      };

    case customerEngagementConstant.GET_CALL_LOGS_REQUEST:
      return {
        ...state,
        logsLoading: true,
      };

    case customerEngagementConstant.GET_CALL_LOGS_SUCCESS:
      return {
        ...state,
        logsLoading: false,
        callLogs: action?.item,
        type: "success",
        message: "Call logs fetched successfully",
      };

    case customerEngagementConstant.GET_CALL_LOGS_FAILURE:
      return {
        ...state,
        logsLoading: false,
        message: "Failed to fetch call logs",
        type: "error",
      };

    case customerEngagementConstant.GET_EMAIL_LOGS_REQUEST:
      return {
        ...state,
        logsLoading: true,
      };

    case customerEngagementConstant.GET_EMAIL_LOGS_SUCCESS:
      return {
        ...state,
        logsLoading: false,
        emailLogs: action?.item,
        type: "success",
        message: "Email logs fetched successfully",
      };

    case customerEngagementConstant.GET_EMAIL_LOGS_FAILURE:
      return {
        ...state,
        logsLoading: false,
        message: "Failed to fetch email logs",
        type: "error",
      };

    case customerEngagementConstant.GET_FEEDBACK_REQUEST:
      return {
        ...state,
        feedbackLoading: true,
      };

    case customerEngagementConstant.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackLoading: false,
        feedback: action?.item,
        type: "success",
        message: "Feedback fetched successfully",
      };

    case customerEngagementConstant.GET_FEEDBACK_FAILURE:
      return {
        ...state,
        feedbackLoading: false,
        message: "Failed to fetch feedback",
        type: "error",
      };

    default:
      return state;
  }
}
