import React from "react";
import BackdropLoader from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const Backdrop = ({ show = false, message }) => {
  return (
    <BackdropLoader
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
      open={show}
    >
      <CircularProgress color="inherit" />
      {message && <Typography fontSize={14} fontWeight={600}>{message}</Typography>}
    </BackdropLoader>
  );
};

export default Backdrop;
