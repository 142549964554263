import { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JsSIP from "jssip";
import io from "socket.io-client";
import { customCallActions } from "../actions/customCallActions";
import { CrmService } from "../services";
import { getUser } from "../voc/Common/Utils";

const {
  registerSIP,
  setIncommingCall,
  declineCallRequest,
  getAudioRef,
  startCaptureVoice,
  stopCaptureVoice,
  getSession,
  updateCallLogs,
  setTranscription,
  getStartTime,
  captureAgentVoice,
  stopAgentVoice
} = customCallActions;

const SOCKET_URL = "https://testvocapp-ws.articence.com";
const SIP_WS_URL = "wss://pbx.articence.com:8089/ws";

const REGISTRATION_INTERVAL = 120;
const HEARTBEAT_INTERVAL = 60000;

const SipConnector = () => {
  const dispatch = useDispatch();
  const uaRef = useRef(null);
  const sessionRef = useRef(null);
  const socketRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const heartbeatTimerRef = useRef(null);
  const isConnectedRef = useRef(false);
  const callLogIdRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const getExtensionData = () => {
    return new Promise((resolve, reject) => {
      const user = getUser();
      if (user && user.extension_number && user.extension_password) {
        resolve({
          extension_number: user.extension_number,
          extension_password: user.extension_password,
        });
      } else {
        reject(new Error("User extension data is not available"));
      }
    });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const data = await getExtensionData();

        // Now you can safely use extensionData
        const SIP_URI = `sip:${data.extension_number}@pbx.articence.com`;
        const SIP_PASSWORD = data.extension_password;

        // Proceed with further logic
        setupSocketConnection();
        setupSIPConnection(SIP_URI, SIP_PASSWORD);
      } catch (error) {
        console.error("Error fetching extension data:", error);
      }
    };

    initialize();
  }, []);

  const { callLogId } = useSelector((store) => ({
    callLogId: store.customCall.callLogId,
  }));

  useEffect(() => {
    if (callLogId) {
      callLogIdRef.current = callLogId;
    }
  }, [callLogId]);

  const setupSocketConnection = useCallback(() => {
    socketRef.current = io(SOCKET_URL, {
      transports: ["websocket"],
      upgrade: false,
    });

    socketRef.current.on("connect", () => {
      console.log("Socket.IO connected");
      socketRef.current.emit("toggle_transcription", { action: "start" });
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket.IO disconnected");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket.IO error:", error);
    });
  }, []);

  const setupSIPConnection = useCallback(
    (SIP_URI, SIP_PASSWORD) => {
      const sipSocket = new JsSIP.WebSocketInterface(SIP_WS_URL);
      const configuration = {
        sockets: [sipSocket],
        uri: SIP_URI,
        password: SIP_PASSWORD,
        register: true,
        register_expires: REGISTRATION_INTERVAL,
        connection_recovery_min_interval: 60,
        connection_recovery_max_interval: 60,
        use_preloaded_route: true,
        hack_ip_in_contact: true,
      };

      const ua = new JsSIP.UA(configuration);
      uaRef.current = ua;

      ua.on("connected", () => {
        console.log("WebSocket connected");
        isConnectedRef.current = true;
        startHeartbeat();
      });

      ua.on("disconnected", () => {
        console.log("WebSocket disconnected");
        isConnectedRef.current = false;
        stopHeartbeat();
      });

      ua.on("registered", () => {
        console.log("Registered with SIP server");
        dispatch(registerSIP(ua, "Registered", "registered"));
        dispatch(getSession(sessionRef));
      });

      ua.on("unregistered", () => {
        console.log("Unregistered from SIP server");
        dispatch(registerSIP(null, "Unregistered", "unregistered"));
      });

      ua.on("registrationFailed", (event) => {
        console.error("Registration failed:", event);
        dispatch(registerSIP(null, event.cause, "registration_failed"));
      });

      ua.on("newRTCSession", handleNewRTCSession);

      ua.start();
    },
    [dispatch]
  );

  const startHeartbeat = useCallback(() => {
    if (heartbeatTimerRef.current) return;

    heartbeatTimerRef.current = setInterval(() => {
      if (uaRef.current && socketRef.current) {
        if (!uaRef.current.isConnected() || !uaRef.current.isRegistered()) {
          console.log("Heartbeat detected connection issue");
        }

        if (!socketRef.current.connected) {
          console.log("Heartbeat detected Socket.IO disconnection");
        }
      }
    }, HEARTBEAT_INTERVAL);
  }, []);

  const stopHeartbeat = useCallback(() => {
    if (heartbeatTimerRef.current) {
      clearInterval(heartbeatTimerRef.current);
      heartbeatTimerRef.current = null;
    }
  }, []);

  function transcribeAudio() {
    console.log(callLogIdRef, "callLogIdRef");
    dispatch(stopCaptureVoice()).then(async (audioBlob, customerVoiceCaptureStartTime) => {
      try {
        const formData = new FormData();
        formData.append("file", audioBlob, "recording.webm");
        formData.append("call_log_id", callLogIdRef.current);
        formData.append("start_time", customerVoiceCaptureStartTime);
        formData.append("speaker", "customer");
        const audioUrl = await CrmService.uploadAudio(formData);
        dispatch(setTranscription(audioUrl.data));
      } catch (e) {
        console.error("Error uploading audio:", e);
      }
    });
  }

  const handleAudioStream = (stream) => {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    const dataArray = new Uint8Array(analyser.fftSize);

    let silenceTimeout = null;
    const silenceDuration = 2000;
    let isCurrentlyRecording = false;

    source.connect(analyser);

    const startRecording = () => {
      if (!isCurrentlyRecording) {
        console.log("Recording started");
        dispatch(startCaptureVoice());
        dispatch(stopAgentVoice(mediaRecorderRef, audioChunksRef, callLogIdRef.current))
        isCurrentlyRecording = true;
      }
    };

    const stopRecording = () => {
      if (isCurrentlyRecording) {
        console.log("Recording stopped due to silence");
        transcribeAudio();
        dispatch(captureAgentVoice(mediaRecorderRef, audioChunksRef));
        isCurrentlyRecording = false;
      }
    };

    const detectVoice = () => {
      analyser.getByteTimeDomainData(dataArray);

      const isSpeaking = dataArray.some((value) => Math.abs(value - 128) > 10);

      if (isSpeaking) {
        if (silenceTimeout) {
          clearTimeout(silenceTimeout);
          silenceTimeout = null;
        }
        if (!isCurrentlyRecording) {
          startRecording();
        }
      } else {
        if (!silenceTimeout) {
          silenceTimeout = setTimeout(() => {
            stopRecording();
          }, silenceDuration);
        }
      }

      requestAnimationFrame(detectVoice);
    };

    detectVoice();
  };

  const handleNewRTCSession = useCallback(
    (data) => {
      const session = data.session;
      sessionRef.current = session;

      dispatch(setIncommingCall(session, "outbound"));

      if (session.direction === "incoming") {
        playRingtone();
        dispatch(setIncommingCall(session, "inbound"));
        session.on("peerconnection", (e) => {
          const peerConnection = e.peerconnection;

          peerConnection.addEventListener("track", (event) => {
            if (event.track.kind === "audio") {
              const stream = event.streams[0];
              if (remoteAudioRef.current) {
                remoteAudioRef.current.srcObject = stream;
                remoteAudioRef.current.play().catch((error) => {
                  console.error("Error playing audio:", error);
                });

                handleAudioStream(stream);

                dispatch(
                  getAudioRef({
                    audioRef: remoteAudioRef.current,
                  })
                );
              }
            }
          });
        });
      }

      const sessionEvents = {
        accepted: () => {
          console.log("Call accepted");
          dispatch(getStartTime());
          dispatch(captureAgentVoice(mediaRecorderRef, audioChunksRef));
          window.isCallConnected = true;
          if (session.currentAudioStream) {
            handleAudioStream(session.currentAudioStream);
          }
        },
        ended: () => {
          console.log("Call ended");
          dispatch(updateCallLogs("Completed"));
          dispatch(declineCallRequest());
          dispatch(setTranscription({
            transcription: null,
            message: null,
          }));
          window.isCallConnected = false;
        },
        failed: () => {
          console.log("Call failed");
          dispatch(updateCallLogs("Failed"));
          dispatch(declineCallRequest());
          dispatch(setTranscription({
            transcription: null,
            message: null,
          }));
          window.isCallConnected = false;
        },

        hold: () => {
          console.log("Call on hold");
          window.isCallConnected = true;
        },
        unhold: () => {
          console.log("Call resumed");
          window.isCallConnected = true;
        },
        muted: () => {
          console.log("Call muted");
          window.isCallConnected = true;
        },
        unmuted: () => {
          console.log("Call unmuted");
          window.isCallConnected = true;
        },
      };

      Object.entries(sessionEvents).forEach(([event, handler]) => {
        session.on(event, handler);
      });

      session.connection.ontrack = (event) => {
        const audioRef = (remoteAudioRef.current.srcObject = event.streams[0]);

        session.currentAudioStream = audioRef;

      };
    },

    [dispatch]
  );

  const playRingtone = () => {
    const ringtone = new Audio("/path/to/ringtone.mp3");
    ringtone.loop = true;
    ringtone.play().catch((error) => {
      console.error("Error playing ringtone:", error);
    });
  };

  return <audio ref={remoteAudioRef} autoPlay />;
};

export default SipConnector;
