import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Box,
  Paper,
  Fade,
  Zoom,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  TablePagination,
  Tooltip,
  Button,
  Slide,
} from "@mui/material";
import {
  Close,
  Call,
  Email,
  Feedback,
  AccountBalanceWallet,
  Dashboard,
} from "@mui/icons-material";
import CustomerDashboardContainer from "./CustomerDashboard";
import { User2Icon } from "lucide-react";
import { CrmService } from "../../services";
import CallSummary from "./CallSummary";
import { useDispatch, useSelector } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import Backdrop from "../../voc/Common/Backdrop";

// const data = {
//   call_details: {
//     id: 1615,
//     start_time: "2024-12-09T03:33:29.158014",
//     end_time: "2024-12-09T03:35:23.666914",
//     duration: null,
//     status: "completed",
//     purpose:
//       "Assist a customer in planning the development of a Windows application",
//   },
//   transcripts: [
//     {
//       id: 2244,
//       user_id: 657,
//       customer_id: 135,
//       call_log_id: 1615,
//       speaker: "ai",
//       transcript_text:
//         "Hey there! I'm AVA, your virtual assistant. I'm here to help you plan that Windows app, right? So, what kind of app are we talking about? Is it a game, a tool, or something else entirely?",
//       start_time: "2024-12-09 03:33:30",
//       end_time: "2024-12-09 03:33:30",
//       created_at: "1900-01-01 03:33:30",
//       updated_at: "1900-01-01 03:33:30",
//     },
//     {
//       id: 2245,
//       user_id: 657,
//       customer_id: 135,
//       call_log_id: 1615,
//       speaker: "customer",
//       transcript_text: " I wanted to create a utility based application here.",
//       start_time: "2024-12-09 03:34:10",
//       end_time: "2024-12-09 03:34:10",
//       created_at: "1900-01-01 03:34:10",
//       updated_at: "1900-01-01 03:34:10",
//     },
//     {
//       id: 2246,
//       user_id: 657,
//       customer_id: 135,
//       call_log_id: 1615,
//       speaker: "ai",
//       transcript_text:
//         "Sweet! A utility app can be super helpful! So, what kind of functionality would you like this app to have? For example, will it help with file management, system maintenance, or maybe even automate some tasks?",
//       start_time: "2024-12-09 03:34:13",
//       end_time: "2024-12-09 03:34:13",
//       created_at: "1900-01-01 03:34:13",
//       updated_at: "1900-01-01 03:34:13",
//     },
//     {
//       id: 2247,
//       user_id: 657,
//       customer_id: 135,
//       call_log_id: 1615,
//       speaker: "customer",
//       transcript_text: " I wanted to create a file management application.",
//       start_time: "2024-12-09 03:34:42",
//       end_time: "2024-12-09 03:34:42",
//       created_at: "1900-01-01 03:34:42",
//       updated_at: "1900-01-01 03:34:42",
//     },
//     {
//       id: 2248,
//       user_id: 657,
//       customer_id: 135,
//       call_log_id: 1615,
//       speaker: "ai",
//       transcript_text:
//         "A file management app sounds like a great idea! So, what specific features are you thinking of including in this app? Will it be able to sort files by type, date, or size? Maybe allow for batch renaming or organization? Let me know and I can help you brainstorm!",
//       start_time: "2024-12-09 03:34:44",
//       end_time: "2024-12-09 03:34:44",
//       created_at: "1900-01-01 03:34:44",
//       updated_at: "1900-01-01 03:34:44",
//     },
//     {
//       id: 2249,
//       user_id: 657,
//       customer_id: 135,
//       call_log_id: 1615,
//       speaker: "customer",
//       transcript_text: " Let's disconnect it.",
//       start_time: "2024-12-09 03:35:22",
//       end_time: "2024-12-09 03:35:22",
//       created_at: "1900-01-01 03:35:22",
//       updated_at: "1900-01-01 03:35:22",
//     },
//   ],
//   conversation_summary: {
//     total_exchanges: 6,
//     duration_minutes: 0,
//     ai_summary:
//       "User discusses creating a Windows file management app with virtual assistant AVA.",
//     sentiment_analysis: {
//       sentiment: "Positive",
//       reason:
//         "Conversation is productive and helpful, with both parties engaged.",
//     },
//   },
// };

const CallDetailsPopup = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const [callCount, setCallCount] = useState(0);

  const callLogs = useSelector(
    (state) => state?.customerEngagementLogic?.callLogs?.data?.call_logs
  );

  const emailLogs = useSelector(
    (state) => state?.customerEngagementLogic?.emailLogs?.data?.email_logs
  );

  const feedbackLogs = useSelector(
    (state) => state?.customerEngagementLogic?.feedbackLogs?.data?.event_data
  );

  const engagementData = {
    calls: callLogs,

    emails: emailLogs?.map((email) => ({
      Subject: email.subject,
      text: email.body.replace(/<\/?[^>]+(>|$)/g, ""),
      date: new Date(email.created_at).toLocaleDateString(),
      "No of Email seen": `${email?.opened_count}`,
    })),

    feedbacks: feedbackLogs?.map((feedback) => ({
      survey_name: feedback?.content,
      survey_id: feedback?.survey_id,
      Engagement: feedback.customer_engagement,
      Responsiveness: feedback.customer_responsiveness,
      Responses_count: feedback.positive_responses_count,
    })),
  };

  const paymentHistory = [
    {
      text: "Payment for Invoice #1234",
      date: "2023-08-01",
      amount: "$100",
      status: "Completed",
    },
    {
      text: "Payment for Invoice #5678",
      date: "2023-09-01",
      amount: "$150",
      status: "Completed",
    },
  ];

  const menuItems = [
    { label: "Dashboard", icon: <Dashboard />, key: "dashboard" },
    { label: "Calls", icon: <Call />, key: "calls" },
    { label: "Emails", icon: <Email />, key: "emails" },
    { label: "Feedback", icon: <Feedback />, key: "feedbacks" },
    { label: "Payments", icon: <AccountBalanceWallet />, key: "payments" },
  ];

  console.log("selectedTab", selectedTab);
  const handleGridClick = (tab) => {
    setSelectedTab(tab); // Update selected tab dynamically
  };

  //
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          overflow: "hidden",
          boxShadow: 8,
          height: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "#d1ecff",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          zIndex: 1200,
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <User2Icon color="black" />
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
            Customer Engagement Details
          </Typography>
        </Box>
        <IconButton edge="end" onClick={onClose} sx={{ color: "black" }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: 0,
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            minWidth: "240px",
            bgcolor: "#f5f5f5",
            borderRight: "1px solid #e0e0e0",
            overflow: "auto",
            position: "sticky",
            top: 0,
            height: "100%",
            zIndex: 1100,
          }}
        >
          <List sx={{ p: 2 }}>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.key}
                selected={selectedTab === item.key}
                onClick={() => setSelectedTab(item.key)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  bgcolor: selectedTab === item.key ? "#d1ecff" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: selectedTab === item.key ? "#d1ecff" : "#e3e3e3",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 1,
                    color:
                      selectedTab === item.key ? "#1976d2" : "text.secondary",
                  }}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: selectedTab === item.key ? 600 : 500,
                    color:
                      selectedTab === item.key ? "#1976d2" : "text.primary",
                  }}
                >
                  {item.label}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto", // Enable scroll for content
            height: "100%",
          }}
        >
          <Fade in={open} timeout={500}>
            <Box>
              {selectedTab === "dashboard" && (
                <CustomerDashboardContainer
                  emailCount={emailLogs?.length || 0}
                  callCount={callCount}
                  feedCount={feedbackLogs?.length || 0}
                  onGridClick={handleGridClick}
                  engagementData={engagementData}
                />
              )}

              {selectedTab === "calls" && (
                <ContentSection title="Call History">
                  <StyledTable rows={engagementData.calls} />
                </ContentSection>
              )}
              {selectedTab === "emails" && (
                <ContentSection title="Email History">
                  <StyledTable rows={engagementData.emails} />
                </ContentSection>
              )}
              {selectedTab === "feedbacks" && (
                <ContentSection title="Feedback Responses">
                  <StyledTable rows={engagementData.feedbacks} />
                </ContentSection>
              )}
              {selectedTab === "payments" && (
                <ContentSection title="Payment History">
                  <StyledTable rows={paymentHistory} />
                </ContentSection>
              )}
            </Box>
          </Fade>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// ContentSection and StyledTable components remain the same
const ContentSection = ({ title, children }) => (
  <Box>
    <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
      {title}
    </Typography>
    <Divider sx={{ mb: 2 }} />
    {children}
  </Box>
);

const StyledTable = ({ rows = [] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openSummary, setSummaryOpen] = useState(false);

  const dispatch = useDispatch();

  function handleOpenSummary(callId) {
    dispatch(customCallActions.getCallSummary(callId));
    setSummaryOpen(true);
  }

  const loading = useSelector((state) => state?.customCall?.loading);

  function handleCloseSummary() {
    setSummaryOpen(false);
  }

  const columns = rows.length > 0 ? Object.keys(rows[0]) : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{ borderRadius: 2, overflow: "hidden" }}>
      <TableContainer
        sx={{
          maxHeight: 400, // Set a fixed height for the table container
          overflowY: "auto", // Enable vertical scrolling for the table content
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col}
                  sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
                >
                  {col.charAt(0).toUpperCase() + col.slice(1)}
                </TableCell>
              ))}
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
                >
                  Summary
                </TableCell>
              </TableRow>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  sx={{ color: "text.secondary" }}
                >
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              paginatedRows?.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((col) => (
                    <TableCell
                      key={col}
                      sx={{ borderBottom: "1px solid #eee" }}
                    >
                      {col === "text" && row[col] ? (
                        <Tooltip title={row[col]} arrow>
                          <span>
                            {row[col].length > 16
                              ? `${row[col].slice(0, 16)}...`
                              : row[col]}
                          </span>
                        </Tooltip>
                      ) : // Ensure fallback value is always a valid ReactNode
                      row[col] !== undefined && row[col] !== null ? (
                        row[col]
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  ))}
                  <TableCell sx={{ borderBottom: "1px solid #eee" }}>
                    <Button onClick={() => handleOpenSummary(row.id)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]} // Options for rows per page
        component="div"
        count={rows.length} // Total number of rows
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {loading ? (
        <Backdrop show={loading} message="Loading call summary..." />
      ) : (
        <CallSummary open={openSummary} handleClose={handleCloseSummary} />
      )}
    </Paper>
  );
};

export default CallDetailsPopup;
