import { crmConstants } from "../constants/crmConstants";
import { CrmService } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";

const request = (message) => ({
  type: crmConstants.GET_CUSTOMER_REQUEST,
  message,
});

const success = (message) => ({
  type: crmConstants.GET_CUSTOMER_SUCCESS,
  message,
});

const error = (message) => ({ type: crmConstants.GET_CUSTOMER_ERROR, message });

const clear = () => ({ type: crmConstants.GET_CUSTOMER_CLEAR });

const uploadCustomers = (csvData, userID) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.uploadCustomers(csvData, userID).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const addBulkCustomers = (data, Id) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.addBulkCustomers(data, Id).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const uploadDuplicateData = (duplicateCSVData, customerId) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.uploadCustomers(duplicateCSVData, customerId).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateCustomerDataFormAPI = (customerId, updateCustomerPayload) => {
  const request = () => ({
    type: crmConstants.EDIT_CUSTOMER_REQUEST,
  });

  const success = (alerts) => ({
    type: crmConstants.EDIT_CUSTOMER_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: crmConstants.EDIT_CUSTOMER_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());

    CrmService.updateCustomerDataFormAPI(
      customerId,
      updateCustomerPayload
    ).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Customer details updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const addNotes = (addNotesData, Id) => {
  const request = () => ({
    type: crmConstants.ADD_NOTES_REQUEST,
  });

  const success = (notes) => ({
    type: crmConstants.ADD_NOTES_SUCCESS,
    notes,
  });

  const failure = (error) => ({
    type: crmConstants.ADD_NOTES_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    CrmService.addNotes(addNotesData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Notes added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteCustomer = (customerId) => {
  const request = () => ({
    type: crmConstants.DELETE_CUSTOMER_REQUEST,
  });

  const success = (alerts) => ({
    type: crmConstants.DELETE_CUSTOMER_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: crmConstants.DELETE_CUSTOMER_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    CrmService.deleteCustomer(customerId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Customer deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const editNotes = (customerId, notesId, editNotesPayload) => {
  const request = () => ({
    type: crmConstants.EDIT_CUSTOMER_REQUEST,
  });

  const success = (alerts) => ({
    type: crmConstants.EDIT_NOTES_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: crmConstants.EDIT_NOTES_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    CrmService.editNotes(customerId, notesId, editNotesPayload).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Notes updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteNotes = (customerId, notesId) => {
  const request = () => ({
    type: crmConstants.DELETE_NOTES_REQUEST,
  });
  
  const success = (noteId) => ({
    type: crmConstants.DELETE_NOTES_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: crmConstants.DELETE_NOTES_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    CrmService.deleteNotes(customerId, notesId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Customer deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getEmailDetails = (surveyId) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.getEmailDetails(surveyId).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const addCustomer = (customerInfo) => {
  const request = () => ({
    type: crmConstants.ADD_CUSTOMER_REQUEST,
  });
  const success = (alerts) => ({
    type: crmConstants.ADD_CUSTOMER_SUCCESS,
    alerts,
  });
  const failure = (error) => ({
    type: crmConstants.ADD_CUSTOMER_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    CrmService.addCustomer(customerInfo).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Customer added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

// const  addCustomer = (customerInfo) => {
//   return (dispatch) => {
//     dispatch(request());

//     CrmService.addCustomer(customerInfo).then(
//       (alerts) => {
//         dispatch(success(alerts));
//         swalAlert({
//           icon: "success",
//           title: "Customer added successfully!",
//           showConfirmButton: true,
//         });
//       },
//       (error) => {
//         dispatch(error(error?.response?.data?.message?.toString()));
//         swalAlert({
//           icon: "error",
//           title: error?.response?.data?.message?.toString(),
//           showConfirmButton: true,
//         });
//       }
//     );
//   };
// };

const getAllCustomers = (userID) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.getAllCustomers(userID).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAllScheduledCalls = (userID) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.getAllScheduledCalls(userID).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getCustomerDataById = (customerId) => {
  return (dispatch) => {
    dispatch(request());

    CrmService.getCustomerDataById(customerId).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const sendEmail = (details, customerId) => {
  const request = (details) => ({
    type: crmConstants.SEND_EMAIL_REQUEST,
    details,
  });
  const success = (details) => ({
    type: crmConstants.SEND_EMAIL_SUCCESS,
    details,
  });
  const failure = (details) => ({
    type: crmConstants.SEND_EMAIL_FAILURE,
    details,
  });
  return (dispatch) => {
    dispatch(request(details));
    console.log(customerId, "<===customerId");
    CrmService.sendEmail(details, customerId).then(
      (details) => {
        dispatch(success(details));
        swalAlert({icon: 'success', title: `Email successfully sent.`, showConfirmButton: true });
      },
      (error) => {
        dispatch(failure(details, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const sendEmailSMTP = (details) => {
  const request = (details) => ({
    type: crmConstants.SEND_EMAIL_REQUEST,
    details,
  });
  const success = (details) => ({
    type: crmConstants.SEND_EMAIL_SUCCESS,
    details,
  });
  const failure = (details) => ({
    type: crmConstants.SEND_EMAIL_FAILURE,
    details,
  });
  return (dispatch) => {
    dispatch(request(details));
    CrmService.sendSMTPMail(details).then(
      (details) => {
        dispatch(success(details));
        swalAlert({icon: 'success', title: `Email successfully sent.`, showConfirmButton: true });
      },
      (error) => {
        dispatch(failure(details, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAllNotes = (id) => {
  const request = () => ({
    type: crmConstants.GET_ALL_NOTES_REQUEST,
  });

  const success = (notes) => ({
    type: crmConstants.GET_ALL_NOTES_SUCCESS,
    notes,
  });

  const failure = (error) => ({
    type: crmConstants.GET_ALL_NOTES_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    CrmService.getAllNotes(id).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const crmActions = {
  getAllCustomers,
  getAllScheduledCalls,
  uploadCustomers,
  getAllNotes,
  addCustomer,
  addNotes,
  uploadDuplicateData,
  getCustomerDataById,
  updateCustomerDataFormAPI,
  getEmailDetails,
  editNotes,
  deleteCustomer,
  deleteNotes,
  success,
  error,
  clear,
  sendEmail,
  sendEmailSMTP,
  addBulkCustomers
};
