import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import { Provider } from "react-redux";
import { store } from "./helpers";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter as Router } from "react-router-dom"; // Use BrowserRouter as Router

import * as serviceWorker from "./serviceWorker";

import {
  CLIENT_ID,
  API_KEY,
  DISCOVERY_DOCS,
  SCOPES,
} from "./voc/Common/Constants";
import SipConnector from "./components/SipConnector";
import { Box } from "@mui/material";

// Disable console logs in production
if (process.env.NODE_ENV !== "development") console.log = () => {};

ReactDOM.render(
  <React.Fragment>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <Router> {/* Wrap App with Router */}
          <SipConnector />
          <GoogleOAuthProvider clientId={CLIENT_ID}>
  
          <App />
  
        </GoogleOAuthProvider>
        </Router>
      </Provider>
    </DndProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
