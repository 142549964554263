import React, { useState } from "react";
import {
  Button,
  Typography,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  Switch,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const CreateRoleForm = () => {
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({
    roleName: "",
    roleDescription: "",
    menus: {},
    permissions: {},
  });

  const menuList = [
    { main: "Home", sub: [] },
    { main: "Users & Team", sub: ["Users", "Managers", "Admin", "Team"] },
    { main: "Calls & Agents", sub: ["Make Call", "Call Agents", "Call Rules"] },
    { main: "External data & Integrations", sub: ["API Integration", "Messaging Integration", "Analysis"] },
    { main: "Territory & Delegation", sub: ["Territory", "Delegation"] },
    { main: "Escalations", sub: ["Escalate", "Transfer summary"] },
    { main: "Calendars & Appointments", sub: ["Calendar"] },
    { main: "Payments & subscription", sub: ["Payment", "Subscription"] },
    { main: "Access & Roles", sub: ["Roles"] },
    { main: "Customer Experience", sub: ["Survey", "Forms"] },
    { main: "Help", sub: [] },
  ];

  const permissionsList = [
    "View",
    "Add",
    "Delete",
    "Modify",
  ];

  const [openMenus, setOpenMenus] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMenuToggle = (menu) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleMainMenuChange = (mainMenu, checked) => {
    setFormData((prevState) => {
      const updatedSubMenus = checked ? menuList.find((m) => m.main === mainMenu)?.sub || [] : [];
      return {
        ...prevState,
        menus: {
          ...prevState.menus,
          [mainMenu]: checked ? updatedSubMenus : [],
        },
      };
    });
  };

  const handleSubMenuChange = (mainMenu, subMenu, checked) => {
    setFormData((prevState) => {
      const currentMenu = prevState.menus[mainMenu] || [];
      const updatedSubMenus = checked
        ? [...currentMenu, subMenu]
        : currentMenu.filter((item) => item !== subMenu);

      // If at least one submenu is selected, select the main menu
      const mainMenuChecked = updatedSubMenus.length > 0;

      return {
        ...prevState,
        menus: {
          ...prevState.menus,
          [mainMenu]: updatedSubMenus,
        },
      };
    });
  };

  const handlePermissionChange = (permission) => (e) => {
    const { checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      permissions: {
        ...prevState.permissions,
        [permission]: checked,
      },
    }));
  };

  const handleSubmit = () => {
    console.log("Payload:", formData);
    alert("Role created successfully!");
    setOpenForm(false);
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Roles
      </Typography>

      {!openForm && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenForm(true)}
          sx={{ marginBottom: "10px" }}
        >
          Add Role
        </Button>
      )}

      {openForm && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              padding: 3,
              border: "1px solid #ccc",
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            {/* Left Section */}
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Role Name"
                name="roleName"
                value={formData.roleName}
                onChange={handleInputChange}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Role Description"
                name="roleDescription"
                value={formData.roleDescription}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>

            {/* Center Section */}
            <Box
              sx={{
                flex: 1,
                border: "1px solid #ccc",
                borderRadius: 2,
                padding: 2,
                maxHeight: 300,
                overflowY: "auto",
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Menus
              </Typography>
              {menuList.map(({ main, sub }) => (
                <Box key={main} sx={{ marginBottom: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      checked={(formData.menus[main]?.length || 0) > 0}
                      onChange={(e) =>
                        handleMainMenuChange(main, e.target.checked)
                      }
                    />
                    {openMenus[main] ? (
                      <ExpandLess
                        sx={{ marginRight: 1 }}
                        onClick={() => handleMenuToggle(main)}
                      />
                    ) : (
                      <ExpandMore
                        sx={{ marginRight: 1 }}
                        onClick={() => handleMenuToggle(main)}
                      />
                    )}
                    <Typography onClick={() => handleMenuToggle(main)}>
                      {main}
                    </Typography>
                  </Box>
                  <Collapse in={openMenus[main]}>
                    <FormGroup sx={{ pl: 2, fontSize: '0.875rem' }}> {/* Adjust padding and font size */}
                      {sub?.map((submenu) => (
                        <FormControlLabel
                          key={submenu}
                          control={
                            <Checkbox
                              sx={{
                                transform: 'scale(0.8)', // Reduce the size of the checkbox
                                padding: '4px', // Adjust padding around the checkbox
                              }}
                              checked={
                                formData.menus[main]?.includes(submenu) || false
                              }
                              onChange={(e) =>
                                handleSubMenuChange(
                                  main,
                                  submenu,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={submenu}
                          sx={{
                            fontSize: '0.875rem', // Adjust the font size of the label
                            ml: 0.5, // Adjust margin if needed
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Collapse>

                </Box>
              ))}
            </Box>

            {/* Right Section */}
            <Box
              sx={{
                flex: 1,
                border: "1px solid #ccc",
                borderRadius: 2,
                padding: 2,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Permissions
              </Typography>
              {permissionsList.map((permission) => (
                <Box
                  key={permission}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Typography>{permission}</Typography>
                  <Switch
                    checked={formData.permissions[permission] || false}
                    onChange={handlePermissionChange(permission)}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenForm(false)}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default CreateRoleForm;
