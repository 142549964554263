import React, { useState } from "react";
import DataTable from "../../../components/Tables/DataTable";
import TeamCreationForm from "./TeamCreationForm";
import { Button, Typography } from "@mui/material";

const TeamTable = () => {
  const [showForm, setShowForm] = useState(false);

  const columns = ["Team Name", "Description", "Territories"];
  const rows = [
    { "Team Name": "Sales and Marketing", Description: "Handles Sales and client relation", Territories: "North" },
    { "Team Name": "Technical", Description: "Develop and maintain the product", Territories: "East" },
    // Add more rows as needed
  ];

  const handleAddTeam = () => {
    setShowForm(true);
  };

  const handleCancelForm = () => {
    setShowForm(false);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleAddTeam}>
          Add Team
        </Button>
      </div>
      {showForm && (
        <div style={{ marginBottom: "20px" }}>
          <TeamCreationForm onCancel={handleCancelForm} />
        </div>
      )}
      <Typography variant="h6" gutterBottom>
        Team Details
      </Typography>
      <DataTable columns={columns} rows={rows} tableType="Team" />
    </div>
  );
};

export default TeamTable;
