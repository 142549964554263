import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import {
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUser, history } from "../../../helpers";
import Tooltip from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { enrichApolloUser } from "../../../services/apollo"
import { IntegrationService } from "../../../services/IntegrationService";

import { crmActions } from "../../../actions";
import { Box, Checkbox, Chip, Stack, Button } from "@mui/material";


const ExternalCustomerTable = ({ dataSource, dataSource2, currentFilter, handleCustomerAdd, handleShow, handleAddSelected, allCustomers }) => {
  const [data, setData] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState([]);

  // Select All
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterQuery, setFilterQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleAddMultiple = () => {
    handleAddSelected(); // Call the parent's function when the button is clicked
    // Perform any additional actions for adding selected rows
  };
  console.log("allCustomers123",allCustomers);
  

  useEffect(() => {
    if (dataSource2) {
      setData(dataSource2);
    }
  }, [dataSource2]);

  const sortData = (field) => {
    setSortField(field);
    const sortedData = [...data].sort((a, b) => {
      if (a[field] < b[field]) return sortOrder === "asc" ? -1 : 1;
      if (a[field] > b[field]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filterData = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    setCurrentPage(1); // Reset to first page on filter change
  };
  
  const filteredData = data.filter((item) => {
    // Check if any field in the row contains the query
    return Object.values(item).some((field) => {
      // Check if the field is an object; if so, check its values
      if (typeof field === "object" && field !== null) {
        return Object.values(field)
          .join(" ")
          .toLowerCase()
          .includes(filterQuery);
      }
      return String(field).toLowerCase().includes(filterQuery);
    });
  });
  


  // Pagination calculations
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = filteredData.slice(firstItemIndex, lastItemIndex);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Create pagination items
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10;
    const halfPageNumbers = Math.floor(maxPageNumbers / 2);

    let startPage = Math.max(currentPage - halfPageNumbers, 1);
    let endPage = Math.min(currentPage + halfPageNumbers, totalPages);

    if (startPage <= halfPageNumbers) {
      endPage = Math.min(maxPageNumbers, totalPages);
    }
    if (endPage >= totalPages - halfPageNumbers) {
      startPage = Math.max(totalPages - maxPageNumbers + 1, 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      pageNumbers.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
      pageNumbers.unshift(
        <Pagination.Item key={1} onClick={() => paginate(1)}>
          1
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" />);
      pageNumbers.push(
        <Pagination.Item key={totalPages} onClick={() => paginate(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      // Select all current page items
      const allSelected = currentItems.map((item) => item.id);
      setSelectedRows(allSelected);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(id);

      if (isSelected) {
        // Deselect the row
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        // Select the row
        return [...prevSelectedRows, id];
      }
    });
  };


  const getTimezoneOffset = (timezone) => {
    try {
      if (!timezone) throw new Error("Invalid or missing timezone");
      const date = new Date();
      const utcDate = new Date(
        date.toLocaleString("en-US", { timeZone: "UTC" })
      );
      const localDate = new Date(
        date.toLocaleString("en-US", { timeZone: timezone })
      );
      const diff = localDate - utcDate;
      return diff / (1000 * 60 * 60); // Convert to hours
    } catch (error) {
      console.error("Error calculating timezone offset:", error.message);
      return 0; // Fallback to 0 offset if timezone is invalid
    }
  };

  useEffect(() => {
    // Filter and map selected rows
    const updatedData = currentItems
      .filter((item) => selectedRows.includes(item.id)) // Only include selected rows
      .map((item) => [
        item.id,
        getTimezoneOffset(item.timezone || "UTC"), // Use "UTC" as fallback timezone
      ]);

    // Update state only if data or selection state changes
    if (
      JSON.stringify(updatedData) !== JSON.stringify(selectedData) || // Check if data has changed
      selectAll !==
        (currentItems.length > 0 &&
          currentItems.every((item) => selectedRows.includes(item.id)))
    ) {
      setSelectedData(updatedData); // Store in selectedData
      setSelectAll(
        currentItems.length > 0 &&
          currentItems.every((item) => selectedRows.includes(item.id))
      );
    }
  }, [selectedRows, currentItems, selectedData, selectAll]);

 
  const handleAddMulti = async () => {
    if (selectedRows.length === 0) {
      console.log("No customers selected");
      return;
    }
  
    setIsLoading(true); // Show the loading popup
  
    try {
      // Find all selected rows' data
      const customersToAdd = data.filter((row) => selectedRows.includes(row.id));
  
      console.log("Customers to Add:", customersToAdd);
  
      // Call addAppCustomer for each selected customer
      for (const customer of customersToAdd) {
        await addAppCustomer(customer);
      }
  
      console.log("All selected customers added successfully");
      handleAddSelected(customersToAdd);     
    } catch (error) {
      console.error("Error adding selected customers:", error);
    } finally {
      setIsLoading(false); // Hide the loading popup
      setSelectedRows([]); // Clear selected rows after processing
      setSelectAll(false);
    }
    
  };
  

  const badgeColor = {
    lunch: "warning",
    within: "success",
    outside: "error",
  };

  const LoadingPopup = ({ visible }) => {
    if (!visible) return null;
  
    return (
      <div style={{ 
        position: 'fixed', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        backgroundColor: 'rgba(0, 0, 0, 0.75)', 
        color: '#fff', 
        padding: '20px', 
        borderRadius: '8px', 
        textAlign: 'center',
        zIndex: 1000 ,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div style={{ marginBottom: '10px' }}>
          {/* Spinner */}
          <div className="spinner" style={{
            width: '40px',
            height: '40px',
            border: '4px solid #fff',
            borderTop: '4px solid transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
          }}></div>
        </div>
        Customer adding in VOC...
      </div>
    );
  };
  
  // CSS for Spinner Animation
  const styles = document.createElement('style');
  styles.innerHTML = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(styles);

  const addAppCustomer = async (appData) => {


    const payload1 = {
      api_provider_id : 24,
     
      query_params:{
      first_name: appData.first_name,
      last_name: appData.last_name,
      linkedin_url: appData.linkedin_url,
      reveal_personal_emails: false,
      reveal_phone_number: false,
      webhook_url: "https://webhook.site/74b4ce55-ce66-4b93-afe0-4ee735ff48b9"
      }
  }
  
    //Prepare the payload for the enrich API call
    const enrichPayload = {
      first_name: appData.first_name,
      last_name: appData.last_name,
      linkedin_url: appData.linkedin_url,
      reveal_personal_emails: false,
      reveal_phone_number: false,
      webhook_url: "https://webhook.site/74b4ce55-ce66-4b93-afe0-4ee735ff48b9"
    };
  
    try {
      setIsLoading(true);
      // Call the enrich API and update state with enriched data
      //const enrichedData = await enrichApolloUser(enrichPayload);
      const response2 = await IntegrationService.fetchEnrichApollo(payload1);
      const enrichedData = response2?.data
      console.log("response2", response2);
      console.log("enrichedData", enrichedData);
  
      // Prepare formData from the enriched data
      const formData = {
        first_name: enrichedData.person.first_name || appData.first_name,
        last_name: enrichedData.person.last_name || appData.last_name,
        email: enrichedData.person.email || appData.email,
        company: enrichedData.person.organization?.name || appData.organization?.name || "",
        account_owner: enrichedData.person.account?.name || appData.account?.name || "",
        account_team: enrichedData.person.account?.publicly_traded_exchange || appData.account?.publicly_traded_exchange || "",
        annual_revenue: enrichedData.person.organization.annual_revenue || 20000, // Fallback to placeholder if data is unavailable
        city: enrichedData.city || appData.city || "",
        company_address: enrichedData.person.organization.raw_address || "",
        company_city: enrichedData.person.organization.city || "",
        company_country: enrichedData.person.organization.country || "",
        company_phone: enrichedData.person.organization?.sanitized_phone || appData.organization?.sanitized_phone || "",
        company_state: enrichedData.person.organization.state || "",
        country: enrichedData.person.country || appData.country || "",
        departments: enrichedData.person.departments || "",
        facebook_url: enrichedData.person.facebook_url || "",
        first_phone: enrichedData.person.organization?.sanitized_phone || appData.organization?.sanitized_phone || "1234578910",
        industry: enrichedData.person.organization.industry || "",
        keywords: enrichedData.person.organization.keywords || "",
        linkedin_url: enrichedData.person.linkedin_url || appData.linkedin_url || "",
        mobile_phone: enrichedData.person.organization?.sanitized_phone || appData.organization?.sanitized_phone || "1234578910",
        number_of_employees: null,
        state: enrichedData.person.state || appData.state || "",
        technologies: enrichedData.person.organization.technology_names || "",
        timezone: enrichedData.person.timezone || "Asia/Dubai",
        title: enrichedData.person.title || appData.title || "",
        twitter_url: enrichedData.person.twitter_url || "",
        work_direct_phone: enrichedData.person.organization?.sanitized_phone || appData.organization?.sanitized_phone || "1234578910"
      };
  
      // Introduce a 1-second delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
  //console.log("formData", formData)
      // Dispatch the action
      dispatch(crmActions.addCustomer(formData)); //add customer
      setIsLoading(false);
      handleAddSelected();
    } catch (error) {
      console.error("Error in enriching or adding customer:", error);
    }
  };
  
  

  // Helper function to render filter bubbles
  const renderFilterBubbles = () => {
    console.log("currentFilter.organization_num_employees_ranges", currentFilter.organization_num_employees_ranges);
    console.log("currentFilter.organization_num_employees_ranges", currentFilter.organization_locations);
    
    const filterItems = [];
    
    // Check if each filter has a value and render accordingly
    if (currentFilter.organization_locations) {
      filterItems.push({
        label: `organization_locations: ${currentFilter.organization_locations}`,
        key: 'organization_locations',
      });
    }

    if (currentFilter.organization_num_employees_ranges && currentFilter.organization_num_employees_ranges.length > 1) {
      filterItems.push({
        label: `organization_num_employees_ranges: ${currentFilter.organization_num_employees_ranges.join(', ')}`,
        key: 'organization_num_employees_ranges',
      });
    }

    if (currentFilter.person_locations) {
      filterItems.push({
        label: `person_locations: ${currentFilter.person_locations}`,
        key: 'person_locations',
      });
    }

    if (currentFilter.person_titles && currentFilter.person_titles.length > 0) {
      filterItems.push({
        label: `person_titles: ${currentFilter.person_titles.join(', ')}`,
        key: 'person_titles',
      });
    }

    if (currentFilter.q_organization_domains) {
      filterItems.push({
        label: `q_organization_domains: ${currentFilter.q_organization_domains}`,
        key: 'q_organization_domains',
      });
    }

  return filterItems.map((item) => (
    <Chip
      key={item.key}
      label={item.label}
      variant="outlined"
      color="primary"
      sx={{
        backgroundColor: 'lightgrey',
        fontSize: '0.75rem',
        margin: '0 5px',
        padding: '5px 10px',
        borderRadius: '20px',
        color: 'black'
      }}
    />
  ));
};

  return (
    <div style={{ margin: "20px auto", border: "0px solid red" }}>
      <Form.Group className="mb-3" controlId="filterQuery">
        <Form.Control
          type="text"
          placeholder="Search by any field..."
          onChange={filterData}
        />
      </Form.Group>
      {/* Render Filter Bubbles */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 1,
          pb: 2,
          px: 2,
          flexWrap: 'wrap',
        }}
      >
        {renderFilterBubbles()}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          pb: 2,
          px: 2,
        }}
      >
        <DropdownButton id="dropdown-basic-button" title="Sort By" size="sm">
          <Dropdown.Item onClick={() => sortData("name")}>
            Name
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sortData("title")}>
            title
          </Dropdown.Item>
        </DropdownButton>

        {selectedRows.length > 0 && (
          <Stack direction="row" spacing={2}>
            <Button
              size="sm"
              color="primary"
              variant="contained"
              onClick={handleAddMulti}
            >
              Add Selected
            </Button>
          </Stack>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ padding: ".2rem !important" }}
              >
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Seniority</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Company Phone</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {filteredData.map((row) => {
    // Check if the email exists in allcustomer
    const isAlreadyAdded = allCustomers.some(
      (customer) => customer.email === row.email
    );

    return (
      <TableRow
        key={row.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ padding: "0rem !important" }}
        >
          <Checkbox
            checked={selectedRows.includes(row.id)}
            onChange={() => handleRowSelect(row.id)}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>{row.organization.name}</TableCell>
        <TableCell>{row.seniority}</TableCell>
        <TableCell>{row.country}</TableCell>
        <TableCell>{row.organization.phone}</TableCell>
        <TableCell>{row.organization.website_url}</TableCell>
        <TableCell>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Conditionally Render Label */}
            {isAlreadyAdded ? (
              <span
                style={{
                  backgroundColor: "#ff9800",
                  color: "white",
                  borderRadius: "12px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "4px 8px",
                  marginRight: "8px",
                  display: "inline-block",
                  animation: "fadeIn 1.2s ease-in-out",
                }}
              >
                Already Added
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: "#4caf50",
                  color: "white",
                  borderRadius: "12px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "4px 8px",
                  marginRight: "8px",
                  display: "inline-block",
                  animation: "fadeIn 1.2s ease-in-out",
                }}
              >
                NEW
              </span>
            )}

            {/* Add Button */}
            {/* <Tooltip title="Add as a VOC customer">
              <IconButton
                style={{
                  border: "1px solid gray",
                  borderRadius: "4px",
                  padding: "4px 6px",
                  cursor: "pointer",
                }}
                onClick={() => addAppCustomer(row)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip> */}
          </div>

          {/* Animation for the label */}
          <style>
            {`
              @keyframes fadeIn {
                0% { opacity: 0; transform: scale(0.9); }
                100% { opacity: 1; transform: scale(1); }
              }
            `}
          </style>
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>

        </Table>
      </TableContainer>
      <Pagination>
        <Pagination.Prev
          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
        />
        {renderPageNumbers()}
        <Pagination.Next
          onClick={() =>
            paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
          }
        />
      </Pagination>
      <LoadingPopup visible={isLoading} />
    </div>
  );
};

export default ExternalCustomerTable;
