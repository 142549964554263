import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../common/controls/inputs/Input";
import Button from "../../common/controls/Button";
import Label from "../../common/controls/inputs/Label";

import MicrosoftAcc from "../../components/Email_Integration/Outlook";
import GoogleAcc from "../../components/Email_Integration/Gmail";
import { CommonWrapper, Wrapper } from "../Common/CommonWrapper";
import { setLocalStorage } from "../Common/Utils";
import { INPUT_ERRORS } from "../Common/Constants";
import { history } from "../../helpers";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

import { userActions } from "../../actions";

import "./style.css";

const LoginContent = ({
  otherInfo: {
    loggingIn,
    dispatch,
    inputs: oldInputs,
    setInputs: setOldInputs,
    submitted,
    setSubmitted,
    location,
  },
}) => {
  const [inputs, setInputs] = useState(oldInputs),
    { username, password } = inputs,
    [visibility, setVisibilty] = useState(false),
    handleOnChange = (e) => {
      const { name, value } = e.target;
      setInputs((inputs) => ({ ...inputs, [name]: value.trim() }));
    },
    handleSubmit = () => {
      setSubmitted(true);
      setOldInputs(inputs);
      if (username && password && !loggingIn) {
        const { from } = { from: { pathname: "/" } };
        dispatch(userActions.login(username, password, from, false));
      }
    },
    onkeyPress = (e) => {
      if (e && e.key === "Enter") {
        handleSubmit();
      }
    },
    // handleKeyPress = (e) => {
    //   console.log("q",e)
    //   if(e.key==="Enter"){
    //     setSubmitted(true);
    //   setOldInputs(inputs);
    //   if (username && password && !loggingIn) {
    //     const { from } = { from: { pathname: "/" } };
    //     dispatch(userActions.login(username, password, from, false));

    //   }
    //   }
    // },

    onSocialLogin = (profile) => {
      if (profile?.email) {
        const { from } = location.state || { from: { pathname: "/" } },
          { email, name = "" } = profile,
          splitName = name.split(" ");
        profile["firstName"] = splitName[0];
        profile["lastName"] = splitName.length > 1 ? splitName[1] : "";
        dispatch(userActions.login(email, null, from, profile));
      }
    };

  return (
    <>
      <div className="signUpLink">
        <div className="dontAccount">Don't have an account?</div>
        <div
          className="buttonSignIn"
          onClick={() => {
            history.push("/signup");
          }}
        >
          <span className="signInText">Sign up</span>
        </div>
      </div>

      <div className={"header"}>
        <img src="images/logo.png" className={"logo"} />
        <span className={"heading"}> Sign Into Articence </span>
      </div>

      <div className={"content"}>
        <Input
          config={{
            label: "Username or Email Address",
            className: "input",
            labelClass: "label",
            name: "username",
            placeholder: "Enter Your Username/Email",
            required: true,
          }}
          value={username}
          onChange={handleOnChange}
          isError={submitted && !username}
          error={INPUT_ERRORS.username}
          onKeyPress={onkeyPress}
        />
        <Link
          to="/forgetPassword"
          className="btn btn-link forgotPasswordLink"
          style={{
            // textDecorationLine: "underline",
            position: "absolute",
            top: 130,
            right: 0,
            color: "#8A94A6",
            fontSize: 14,
            letterSpacing: "0.03em",
          }}
        >
          Forgot Password?
        </Link>
        <Input
          config={{
            label: "Password",
            type: visibility ? "text" : "password",
            className: "input",
            labelClass: "label",
            labelStyle: { width: 100 },
            name: "password",
            placeholder: "Enter Your Password",
            required: true,
          }}
          value={password}
          onChange={handleOnChange}
          isError={submitted && !password}
          error={INPUT_ERRORS.password}
          onKeyPress={onkeyPress}
        />
        <div style={{ display: "flex" }}>
          <input
            type="checkbox"
            style={{ height: 18, width: 18 }}
            onChange={(e) => setVisibilty(e?.target?.checked)}
          />
          &nbsp;Show Password
        </div>
        <Button
          text="Sign In"
          customCSS="submit_btn"
          btnTextClass="submit_btn-txt"
          clickHandler={handleSubmit}
        />

        <div className="social_login_section">
          <div className="divider">
            <div className="divider-text"></div>
            <span className="signText"> Or sign in with</span>
            <div className="divider-text"></div>
          </div>
          <div className="social_login_button">
            <GoogleAcc
              showOnlyIcon={true}
              style={{ top: "auto", left: "35.3%", cursor: "pointer" }}
              onSuccess={onSocialLogin}
            />
            <MicrosoftAcc
              showOnlyIcon={true}
              style={{ top: "auto", left: "55.5%", cursor: "pointer" }}
              onSuccess={onSocialLogin}
            />
          </div>
        </div>

        <Link
          to="/teamRegister"
          className="btn btn-link"
          style={{
            marginTop: 50,
            padding: 0,
            textAlign: "left",
            textDecorationLine: "underline",
            fontSize: "0.9rem",
          }}
        >
          Register Team
        </Link>
      </div>
    </>
  );
};

const Login = () => {
  const [inputs, setInputs] = useState({ username: "", password: "" }),
    [submitted, setSubmitted] = useState(false),
    location = useLocation(),
    dispatch = useDispatch(),
    { loggingIn = false, error } = useSelector((state) => state.authentication);

  useEffect(() => {
    setLocalStorage("auth", {});
    dispatch(userActions.logout());
  }, []);

  const image = () => {
      return (
        <>
          <div className="bannerWrapper">
            <div className="bannerWrapper--left">
              <h1>Start your free trial today</h1>
              {/* <h2>No credit card required</h2> */}
              <p>The trial includes:</p>
              <ul>
                <li>
                  <DoneRoundedIcon />
                  Customized data based on your information
                </li>
                <li>
                  <DoneRoundedIcon />
                  Pre-configured dashboards and reports
                </li>
                <li>
                  <DoneRoundedIcon />
                  Customized templates and question banks
                </li>
                <li>
                  <DoneRoundedIcon />
                  Easy help and reference
                </li>
                <li>
                  <DoneRoundedIcon />
                  AI Analysis
                </li>
              </ul>
            </div>

            <div className="bannerWrapper--right">
              <img src="images/mainBannerFull.png" />
            </div>
          </div>
          {/* <img src="images/loginBanner.png" /> */}
        </>
      );
    },
    LeftWrapper = () => <Wrapper component={image} className={"leftWrapper"} />,
    RightWrapper = ({
      loggingIn,
      error,
      dispatch,
      modal,
      setModal,
      inputs,
      setInputs,
      submitted,
      setSubmitted,
      location,
    }) => (
      <Wrapper
        otherInfo={{
          loggingIn,
          error,
          dispatch,
          modal,
          setModal,
          inputs,
          setInputs,
          submitted,
          setSubmitted,
          location,
        }}
        component={LoginContent}
        className={"rightWrapper"}
      />
    ),
    Content = () => (
      <div className={"signInSinUpWrap"}>
        <LeftWrapper />
        <RightWrapper
          loggingIn={loggingIn}
          error={error}
          dispatch={dispatch}
          inputs={inputs}
          setInputs={setInputs}
          submitted={submitted}
          setSubmitted={setSubmitted}
          location={location}
        />
      </div>
    );

  return <CommonWrapper component={Content} isLoader={loggingIn} />;
};

export default Login;
