import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextEditor from "./TextEditor";
import { Button, Box, Typography } from "@mui/material";
import { Editor, EditorState, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";

const Emailbody = ({
  body,
  onChange,
  handleClose,
  sendEmail,
  setBody,
  signIn,
  isSchedule,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const insertText = (text) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      text
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    setEditorState(newEditorState);
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    const content = state.getCurrentContent().getPlainText();
    onChange({ target: { name: "content", value: content } });
  };

  return (
    <div className="form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label>Schedule at</label>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            value={body.schedule}
            variant="outlined"
            size="small"
            fullWidth
            name="schedule"
            type="datetime-local"
            onChange={onChange}
          />
        </Grid>

        {isSchedule && (
          <Grid item xs={12}>
            <label>Interval (in minutes)</label>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              value={body.interval}
              variant="outlined"
              size="small"
              fullWidth
              name="interval"
              type="number"
              onChange={onChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <label>Subject</label>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            value={body.subject}
            variant="outlined"
            size="small"
            fullWidth
            name="subject"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              Content
            </Typography>
            <Box
              sx={{
                display: isSchedule ? "flex" : "none",
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => insertText("{{customer_name}}")}
              >
                + Customer Name
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => insertText("{{company_name}}")}
              >
                + Company Name
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => insertText("{{email}}")}
              >
                + Email
              </Button>
            </Box>
          </Box>
          <div style={{ border: "1px solid #ccc", padding: "10px", minHeight: "100px" }}>
            <Editor
              editorState={editorState}
              onChange={handleEditorChange}
              placeholder="Type your email content here..."
            />
          </div>
        </Grid>
      </Grid>
      <div className="btnGroup">
        <button onClick={handleClose} className="btn btnCancel">
          Cancel
        </button>
        <button onClick={() => sendEmail()} className="btn btnSave">
          Send
        </button>
      </div>
    </div>
  );
};

export default Emailbody;
