export const customerEngagementConstant = {
  // GET DATA
  GET_CUSTOMER_ENGAGEMENT_REQUEST: "GET_CUSTOMER_ENGAGEMENT_REQUEST",
  GET_CUSTOMER_ENGAGEMENT_SUCCESS: "GET_CUSTOMER_ENGAGEMENT_SUCCESS",
  GET_CUSTOMER_ENGAGEMENT_FAILURE: "GET_CUSTOMER_ENGAGEMENT_FAILURE",
  GET_CUSTOMER_ENGAGEMENT_DETAIL: "GET_CUSTOMER_ENGAGEMENT_DETAIL",
  GET_CUSTOMER_ENGAGEMENT_DETAIL_SUCCESS:
    "GET_CUSTOMER_ENGAGEMENT_DETAIL_SUCCESS",
  GET_CUSTOMER_ENGAGEMENT_DETAIL_FAILURE:
    "GET_CUSTOMER_ENGAGEMENT_DETAIL_FAILURE",

  GET_SUMMARY_ENGAGEMENT_REQUEST: "GET_SUMMARY_ENGAGEMENT_REQUEST",
  GET_SUMMARY_ENGAGEMENT_SUCCESS: "GET_SUMMARY_ENGAGEMENT_SUCCESS",
  GET_SUMMARY_ENGAGEMENT_FAILURE: "GET_SUMMARY_ENGAGEMENT_FAILURE",

  GET_CALL_LOGS_REQUEST: "GET_CALL_LOGS_REQUEST",
  GET_CALL_LOGS_SUCCESS: "GET_CALL_LOGS_SUCCESS",
  GET_CALL_LOGS_FAILURE: "GET_CALL_LOGS_FAILURE",

  GET_EMAIL_LOGS_REQUEST: "GET_EMAIL_LOGS_REQUEST",
  GET_EMAIL_LOGS_SUCCESS: "GET_EMAIL_LOGS_SUCCESS",
  GET_EMAIL_LOGS_FAILURE: "GET_EMAIL_LOGS_FAILURE",

  GET_FEEDBACK_REQUEST: "GET_FEEDBACK_REQUEST",
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
  GET_FEEDBACK_FAILURE: "GET_FEEDBACK_FAILURE",
};
