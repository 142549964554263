import { integrationsConstants } from "../constants/integrationConstants";
import { IntegrationService } from "../services/IntegrationService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addIntegration = (addIntegrationData, Id) => {
  const request = () => ({
    type: integrationsConstants.ADD_INTEGRATIONS_REQUEST,
  });

  const success = (integrations) => ({
    type: integrationsConstants.ADD_INTEGRATIONS_SUCCESS,
    integrations,
  });

  const failure = (error) => ({ 
    type: integrationsConstants.ADD_INTEGRATIONS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    IntegrationService.addIntegrationById(addIntegrationData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Integration added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const editIntegration = (addIntegrationData, Id) => {
  
  const request = () => ({
    type: integrationsConstants.EDIT_INTEGRATIONS_REQUEST,
  });

  const success = (alerts) => ({
    type: integrationsConstants.EDIT_INTEGRATIONS_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: integrationsConstants.EDIT_INTEGRATIONS_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    IntegrationService.updateIntegration(addIntegrationData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Integration updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteIntegration = (integrationId) => {  
  const request = () => ({
    type: integrationsConstants.DELETE_INTEGRATIONS_REQUEST,
  });
  
  const success = (noteId) => ({
    type: integrationsConstants.DELETE_INTEGRATIONS_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: integrationsConstants.EDIT_INTEGRATIONS_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    IntegrationService.deleteIntegration(integrationId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Integration deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const integrationAction = {
  addIntegration,
  editIntegration,
  deleteIntegration
};
